import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faHome,
  faListAlt,
  faUser,
  faWallet,
  faSignOutAlt,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Image, Modal } from "@themesberg/react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { Routes } from "../../../routes";
import CustomModal from "../../../components/UI/CustomModal";
import GeneralCart from "../../components/GeneralCart";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useState } from "react";
import Footer from "../../../components/Footer";
import { logout } from "../../../redux/action/auth";
import { Button } from "reactstrap";
import ExpressAcct from "../../ExpressAccount";
import useIsPhoneSize from "../../../utils/useIsPhoneSize";
import AgentTransactions from "../../AgentTransactions";

export default function PaymentsContainer(props) {
  const {
    form = {},
    selected_taxes = [],
    // filterText = "",
    handleTaxFeeChecked = (f) => f,
    handleAddList = (f) => f,
    openModal = (f) => f,
    open = [],
    search,
    menu,
    // title = "",
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, tax_account } = useSelector((a) => a.auth);
  const [showHistory, setShowHistory] = useState(false);
  const toggleHistory = () => setShowHistory(!showHistory);
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const userCanGoto = (sector) => {
    const arr = user?.sector ? user?.sector?.split(',') : []
    if (arr.length && arr.includes(sector)) {
      return true
    } else if (!arr.length) {
      return true
    }
    return false
  }
  const isPhoneSize = useIsPhoneSize();
  const styles = {
    display: "flex",
    flexDirection: "row",
    zIndex: 1,
  };
  const lgStyle = {
    height: isPhoneSize ? "50vh" : "99vh",
    overflowY: "scroll",
    scrollbarWidth: "thin", // For Firefox
    scrollbarColor: "transparent transparent", // For Firefox
    overflow: "-moz-scrollbars-none",
    zIndex: 1,
    paddingBottom: isPhoneSize ? '100px' : '0px',
    marginBottom: isPhoneSize ? '100px' : '0px',

  };
  const paymentBox = {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '75%',
    position: 'fixed',
    bottom: 70,
    padding: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
  const allStyles = isPhoneSize ? styles : lgStyle;
  const [isOpen, setIsOpen] = useState(false);
  const [trx_type, setTrxType] = useState("invoice");

  const toggle = (type) => {
    setIsOpen(!isOpen);
    if (type) {
      setTrxType(type);
    }
  };

  // Array of strings to check for
  const titlesToCheck = [
    "TAX PAYMENTS",
    "NON TAX PAYMENTS",
    "LAND USE FEES/CHARGES",
  ];

  // Check if page_title includes any of the specified strings
  const isTitleIncluded = titlesToCheck.some(function (title) {
    return form?.page_title?.includes(title);
  });


  const containerStyles = {
    height: isPhoneSize ? "50vh" : "auto",
    overflowY: isPhoneSize ? "scroll" : "auto",
  };

  const modalColStyles = {
    position: 'relative',
    height: isPhoneSize ? '50vh' : '99vh',
    zIndex: 1,
    maxHeight: isPhoneSize ? '50vh' : '99vh',
    minHeight: isPhoneSize ? '50vh' : '99vh',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    overflow: '-moz-scrollbars-none',
    margignLeft: '-10px',
    width: '100%',
    paddingBottom: isPhoneSize ? '100px' : '5px'
  };

  return (
    <div>
      <Row className="p-0 m-0">
        {open && (
          <Col
            className={`bg-white ` + isPhoneSize ? 'mb-5 pb-9' : 'p-0 m-0'}
            style={{ height: '50vh' }}
            md={12}
          >
            <Modal
              as={Modal.Dialog}
              show={open}
              onHide={openModal}
              animation={false}
              size="lg"
              className="bg-light text-dark"
              style={{ marginBottom: 40 }}
            >
              <Modal.Header closeButton> SELECT {form.page_title}</Modal.Header>
              <div className="p-1 bg-dark">{search}</div>
              <Modal.Body
                style={modalColStyles}
              >{menu}</Modal.Body>
            </Modal>
          </Col>
        )}
      </Row>
      <Row>
        <div
          style={{
            height: open ? "50vh" : '100vh',
            overflowY: "scroll",
            zIndex: isOpen ? 2 : isPhoneSize ? 9999 : 1,
            scrollbarWidth: "thin",
            // scrollbarColor: "transparent transparent",
            //   overflow: "-moz-scrollbars-none",
          }}
        >
          <Row className="m-0 p-0">
            <Col className="bg-dark text-light px-1 m-0" md={3}>
              <div style={allStyles}>
                {!isPhoneSize && (
                  <center className="p-2">
                    <Image
                      src={require("../../../assets/img/kigra.jpg")}
                      className="mb-3 img-fluid"
                      alt="Logo"
                      style={{
                        borderRadius: "64px",
                        height: isPhoneSize ? "50%" : "40%",
                        width: isPhoneSize ? "50%" : "40%",
                      }}
                    />
                  </center>
                )}
                {user?.role === "user" ? (
                  isPhoneSize ? (
                    <center
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: 50,
                      }}
                      className="mobile-bottom-nav"
                    >
                      <div
                        onClick={() => {
                          history.push(Routes.Selection.path);
                        }}
                        size="sm"
                        className="m-1"
                      >
                        <FontAwesomeIcon
                          icon={faHome}
                          className="me-1"
                          style={{ fontSize: "20px" }}
                        />{" "}
                        <br />
                        Home
                      </div>
                      <div onClick={openModal} size="sm" className="m-1">
                        <FontAwesomeIcon
                          icon={faBars}
                          className="me-1"
                          style={{ fontSize: "20px" }}
                        />
                        <br />
                        Menu
                      </div>
                      <div
                        onClick={() => {
                          history.push(Routes.UserProfile.path);
                        }}
                        size="sm"
                        className="m-1"
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className="me-1"
                          style={{ fontSize: "20px" }}
                        />{" "}
                        <br />
                        Profile
                      </div>
                      <div
                        onClick={() => {
                          history.push(Routes.UserTransactions.path);
                        }}
                        size="sm"
                        className="m-1"
                      >
                        <FontAwesomeIcon
                          icon={faListAlt}
                          style={{ fontSize: "20px" }}
                        />{" "}
                        <br />
                        Ledger
                      </div>
                      <div
                        className="mt-1 mb-1"
                        size="sm"
                        onClick={() => dispatch(logout(history))}
                        color="danger"
                      >
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          style={{ fontSize: "20px" }}
                        />{" "}
                        <br />
                        Logout
                      </div>
                    </center>
                  ) : (
                    <center>
                      <Button
                        onClick={() => {
                          history.push(Routes.Selection.path);
                        }}
                        size="sm"
                        className="btn btn-white m-1"
                      >
                        <FontAwesomeIcon icon={faHome} className="me-1" />
                        Home
                      </Button>
                      <Button
                        onClick={() => {
                          history.push(Routes.UserProfile.path);
                        }}
                        size="sm"
                        className="m-1 bg-tertiary text-white"
                      >
                        <FontAwesomeIcon icon={faUser} className="me-1" />
                        Profile
                      </Button>

                      <Button
                        onClick={() => {
                          history.push(Routes.UserTransactions.path);
                        }}
                        size="sm"
                        className="m-1 btn-secondary"
                      >
                        <FontAwesomeIcon icon={faListAlt} /> Ledger
                      </Button>
                    </center>
                  )
                ) : isPhoneSize ? (
                  <center
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: 50,
                    }}
                    className="mobile-bottom-nav"
                  >
                    <div
                      onClick={() => {
                        history.push(Routes.Selection.path);
                      }}
                      size="sm"
                      className="m-1"
                    >
                      <FontAwesomeIcon
                        icon={faHome}
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />{" "}
                      <br />
                      Home
                    </div>
                    <div onClick={openModal} size="sm" className="m-1">
                      <FontAwesomeIcon
                        icon={faBars}
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />
                      <br />
                      Menu
                    </div>
                    <div
                      onClick={() => {
                        history.push(Routes.UserProfile.path);
                      }}
                      size="sm"
                      className="m-1"
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        className="me-1"
                        style={{ fontSize: "20px" }}
                      />{" "}
                      <br />
                      Profile
                    </div>
                    <div
                      onClick={() => {
                        toggleHistory();
                      }}
                      size="sm"
                      className="m-1"
                    >
                      <FontAwesomeIcon
                        icon={faListAlt}
                        style={{ fontSize: "20px" }}
                      />
                      <br />
                      History
                    </div>
                    <div
                      className="mt-1 mb-1"
                      size="sm"
                      onClick={() => dispatch(logout(history))}
                      color="danger"
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        style={{ fontSize: "20px" }}
                      />{" "}
                      <br />
                      Logout
                    </div>
                  </center>
                ) : (
                  <center>
                    {['Admin', 'Developer'].includes(user.role) && (
                      <Button
                        onClick={() => {
                          history.push(Routes.GenerateReceipt.path);
                        }}
                        size="sm"
                        className="btn bg-tertiary m-1 text-white"
                      >
                        <FontAwesomeIcon icon={faHome} className="me-1" />
                        Dashboard
                      </Button>
                    )}

                    <Button
                      onClick={() => {
                        history.push(Routes.Selection.path);
                      }}
                      size="sm"
                      className="btn btn-white m-1"
                    >
                      <FontAwesomeIcon icon={faHome} className="me-1" />
                      Home
                    </Button>
                    <Button
                      onClick={() => {
                        toggleHistory();
                      }}
                      size="sm"
                      className="m-1 bg-tertiary text-white"
                    >
                      <FontAwesomeIcon icon={faWallet} className="me-1" /> HISTORY
                    </Button>
                  </center>
                )}
                {/* {JSON.stringify({ USER: user?.account_type })} */}

                {isPhoneSize ? (
                  ""
                ) : (
                  <>
                    <h4 className="text-center text-muted mt-1">
                      {form.page_title}
                    </h4>
                    <div
                      className="p-1"
                      style={{
                        width: isPhoneSize ? "100%" : "",
                        height: "10vh",
                        overflowY: "scroll",
                        zIndex: isOpen ? 2 : isPhoneSize ? 9999 : 1,
                        scrollbarWidth: "thin",
                        scrollbarColor: "transparent transparent",
                        overflow: "-moz-scrollbars-none",
                      }}
                    >
                      {search}
                    </div>
                    <div
                      style={allStyles}
                    >
                      {menu}
                    </div>
                  </>
                )}
              </div>
            </Col>
            <Col
              className="bg-white p-0 m-0"
              md={9}
            >
              <Modal as={Modal.Dialog} centered show={showDefault} size="lg">
                <Modal.Header>
                  <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
                  <Button
                    variant="danger"
                    className="bg-danger text-white"
                    aria-label="Close"
                    onClick={toggleModal}
                  >
                    X
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <ExpressAcct isModal={true} />
                </Modal.Body>
              </Modal>

              <Modal
                as={Modal.Dialog}
                fullscreen
                centered
                show={showHistory}
                size="xl"
              >
                <Modal.Header className="bg-dark text-white">
                  <Modal.Title className="h6 ">
                    <h5>
                      <Image
                        src={require("../../../assets/img/kigra.jpg")}
                        className="img-fluid"
                        alt="Logo"
                        style={{
                          borderRadius: "64px",
                          height: "50px",
                          width: "50px",
                        }}
                      />{" "}
                      KANO STATE INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM
                      (KIRMAS){" "}
                      <Image
                        src={require("../../../assets/img/kano.png")}
                        className="img-fluid"
                        alt="Logo"
                        style={{
                          borderRadius: "64px",
                          height: "50px",
                          width: "50px",
                        }}
                      />
                    </h5>
                  </Modal.Title>
                  <Button
                    variant="danger"
                    className="bg-danger text-white"
                    aria-label="Close"
                    onClick={toggleHistory}
                  >
                    X
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <AgentTransactions />
                </Modal.Body>
              </Modal>
              <Row className="text-center bg-dark text-light p-0 m-0">
                {isPhoneSize && open ? <hr /> : <Col>
                  <h5>
                    KANO STATE INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM
                    (KIRMAS)
                  </h5>
                </Col>}
                {isPhoneSize ? null : (
                  <Col md={1}>
                    <Button
                      className="mt-1 mb-1"
                      size="sm"
                      onClick={() => dispatch(logout(history))}
                      color="danger"
                    >
                      Logout
                    </Button>
                  </Col>
                )}
              </Row>
              {/* {JSON.stringify(user)} */}
              {(user &&
                user.mda_name === "Ministry of Land and Physical Planning") ||
                (user && user.role === "agent") || isPhoneSize ? null : (
                <Row>
                  <center>
                    <Button
                      onClick={() => {
                        history.push(Routes.TaxPayment.path);
                      }}
                      size="sm"
                      className={`m-1 ${location.pathname.includes("/tax-payments")
                        ? "bg-tertiary"
                        : "btn-secondary"
                        } text-white px-5`}
                    >
                      <FaUser icon={faWallet} className="me-1" />
                      TAX
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(Routes.NonTaxPayment.path);
                      }}
                      size="sm"
                      className={`m-1 ${location.pathname.includes("non-tax")
                        ? "bg-tertiary"
                        : "btn-secondary"
                        } text-white px-5`}
                    >
                      <FontAwesomeIcon icon={faCreditCard} /> NON TAX
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(Routes.LandPayments.path);
                      }}
                      size="sm"
                      className={`m-1 ${location.pathname.includes("land")
                        ? "bg-tertiary"
                        : "btn-secondary"
                        } text-white px-5`}
                    >
                      <FontAwesomeIcon icon={faCreditCard} /> LAND USE
                    </Button>
                    <Button
                      onClick={() => {
                        history.push(Routes.LgaPayments.path);
                      }}
                      size="sm"
                      className={`m-1 ${location.pathname.includes("lga")
                        ? "bg-tertiary"
                        : "btn-secondary"
                        } text-white px-5`}
                    >
                      <FontAwesomeIcon icon={faCreditCard} /> LGA REVENUE
                    </Button>
                  </center>
                </Row>
              )}
              <div
                style={{
                  height: "70vh",
                  overflowY: "scroll",
                  scrollbarWidth: "thin", // For Firefox
                  scrollbarColor: "transparent transparent", // For Firefox
                  overflow: "-moz-scrollbars-none",
                }}
              >
                {/* <hr className=" mb-1" style={{ borderWidth: 10 }} /> */}
                {form.page_title === "LAND USE FEES/CHARGES" ? null : (
                  <h3 className="text-center fw-bold mt-2">{form.mda_name}</h3>
                )}
                <Row className="p-0 m-0 ">
                  {/* <Col> */}
                  <h5 className="text-center fw-bold mt-2">
                    {form.page_title === "LAND USE FEES/CHARGES"
                      ? form.department
                      : form.tax_code}
                  </h5>
                  {/* </Col> */}
                  <Col>
                    <h6 className="text-center fw-bold">
                      {form.economic_code
                        ? `Revenue Code: ${form.economic_code}`
                        : ""}
                    </h6>
                  </Col>
                </Row>
                <div>
                  <div style={{
                    height: isPhoneSize ? '100vh' : "70vh",
                    overflowY: "scroll",
                    scrollbarWidth: "thin", // For Firefox
                    scrollbarColor: "transparent transparent", // For Firefox
                    overflow: "-moz-scrollbars-none",
                    marginBottom: isPhoneSize && open ? '100px' : "0px",
                    paddingBottom: isPhoneSize && open ? '100px' : "0px",
                  }}>
                    {props.children}
                  </div>
                  <div>
                    {selected_taxes.length || form.amount ? (
                      <Row
                        className={` ${isPhoneSize ? ' mt-0 p-0' : '  mb--6  mt-3'}`}
                        style={isPhoneSize ? {
                          ...paymentBox,
                          margin: 0,
                          padding: 0
                        } : paymentBox}
                      >
                        <Col md={6} sm={6} xs={6} className="text-center">
                          <Button
                            color="orange"
                            onClick={() => {
                              if (tax_account && tax_account?.id > 0) {
                                handleAddList(() => {
                                  toggle("invoice");
                                });
                                if (form.title !== "Presumptive tax") {
                                  // Any additional logic here
                                }
                              } else {
                                toast.error("Please select a Tax Payer");
                              }
                            }}
                            className="bg-warning text-white"
                            style={{ opacity: '1' }} // No opacity for this button
                          >
                            Generate Invoice
                          </Button>
                        </Col>
                        {isTitleIncluded ? (
                          <Col md={6} sm={6} xs={6} className="text-center">
                            <Button
                              onClick={() => {
                                if (tax_account && tax_account?.id > 0) {
                                  if (!form.from || !form.to) {
                                    toast.error("Please select date");
                                  } else {
                                    if (form.title !== "Presumptive tax") {
                                      handleAddList();
                                    }
                                    toggle("payment");
                                  }
                                } else {
                                  toast.error("Please select a Tax Payer");
                                }
                              }}
                              style={{ opacity: '1' }} // No opacity for this button
                            >
                              Pay now
                            </Button>
                          </Col>
                        ) : null}
                      </Row>

                    ) : (
                      ""
                    )}
                    <CustomModal
                      logo={
                        <center className="p-0 text-center">
                          <Image
                            src={require("../../../assets/img/kigra.jpg")}
                            className="img-fluid"
                            alt="Logo"
                            style={{
                              borderRadius: "64px",
                              height: isPhoneSize ? "10%" : "20%",
                              width: isPhoneSize ? "10%" : "20%",
                            }}
                          />
                        </center>
                      }
                      size="lg"
                      toggle={toggle}
                      isOpen={isOpen}
                    >
                      {/* {JSON.stringify(selected_taxes)} */}
                      <GeneralCart
                        form={{ ...form, trx_type }}
                        data={selected_taxes.map((item) => ({
                          ...item,
                          description: item.title,
                          price: item.amount ? item.amount : item?.tax_fee,
                          from: form.from,
                          to: form.to,
                        }))}
                        setModal={setIsOpen}
                        // handleDeleteRow
                        handleTaxFeeChecked={handleTaxFeeChecked}
                      />
                    </CustomModal>
                  </div>
                </div>
              </div>
              <Footer />
            </Col>
          </Row>
        </div>
      </Row>

    </div>
  );
}