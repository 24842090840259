import React, { useState } from "react";
import { Card, CardBody, Button, CardHeader, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus, FaUser } from "react-icons/fa";
import { Row, Modal } from "@themesberg/react-bootstrap";
import ExpressAcct from "../pages/ExpressAccount";
import "./account.css";
import { TAX_ACCOUNT } from "../redux/action/type";
export default function Accounts() {
  const { user, taxaccounts, tax_account } = useSelector((p) => p.auth);
  const dispatch = useDispatch()
  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);

  const handleFocus = (name) => {
    setTimeout(() => {
      //   toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
      // myRef.current.focus();
    }, 2000);
  };
  return (
    <div className="row mb-5">
      <Col md={4}></Col>
      <Col md={4} className="mt-5">
        <Card className="shadow">
          <CardHeader>
            <b>Signin with Kirmas with</b>
          </CardHeader>
          <CardBody>
            <h4 className="text-center">Choose and account</h4>
            <h6 className="text-center mb-5">
              to continue to <span style={{ color: "blue" }}>Kirmas</span>
            </h6>
            <Row className>
              {taxaccounts?.map((item) => (
                <div className={`account ${tax_account.id === item.id ? 'bg-success text-green' : ''}`} onClick={() => {
                  dispatch({
                    type: TAX_ACCOUNT,
                    payload: item,
                  });
                }}
                >
                  <div className="d-flex   ">
                    <div className="">
                      <img
                        src={require("../images/Ahmad-removebg-preview.png")}
                        alt="profile"
                        style={{ width: "50px", cursor: "pointer" }}
                        className="mr-4"
                      />
                    </div>
                    <div className="">
                      <span className="font-weight-bold">
                        {item?.org_name?.toUpperCase() ||
                          item?.name?.toUpperCase()}
                      </span>
                      <br />
                      <span className="text-black-50">
                        TAx ID: {item?.taxID}
                      </span>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
              <div
                onClick={() => toggleModal()}
                className=" shodow btn btn-primary mt-4  "
              >
                <span>Add Account</span>
              </div>
            </Row>

            <Modal
              // style={{ zIndex: showDefault ? 99999 : 1 }}
              as={Modal.Dialog} centered show={showDefault} size="lg">
              <Modal.Header>
                <Modal.Title className="h6" >EXPRESS TAX PAYER FORM</Modal.Title>
                <Button
                  variant="danger"
                  className="bg-danger text-white"
                  aria-label="Close"
                  onClick={toggleModal}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <ExpressAcct isModal={true} isAddAccount={true} tax_id={user.taxID} handleFocus={handleFocus} />
              </Modal.Body>
            </Modal>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}></Col>
    </div>
  );
}
