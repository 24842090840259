import React from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../assets/img/kano.png";
import logo1 from "../assets/img/kigra.jpg";
import {
  formatNumber,
  toCurrency,
  toNairaWords,
  toParagraph,
  toWords,
} from "../utils";
import moment from "moment";
import { toWordsconver } from "../redux/action/api";
import Signature from "../assets/img/mdsign.png";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
import { Badge } from "reactstrap";
Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
      // fontWeight: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "20px",
    height: "400",
    pageBreakInside: "avoid",
    fontSize: 10.5,
    fontFamily: "DM_SANS",
  },
  header: {
    textAlign: "center",
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkText: { fontSize: "50px", transform: "rotate(60deg)" },
  itemContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid #000",
    marginBottom: 5,
    padding: 5,
  },
  qrcodeContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
    height: "100px",
    width: "100px",
  },
  signatureContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  signatureImage: {
    width: "120px",
    height: "30px",
  },
  qrcodeImage: {
    maxWidth: "120px",
    height: "100px",
  },
});

const ReceiptPreview = ({ data = [], qrcode, label = "PREVIEW" }) => {
  const total = data?.reduce((it, id) => it + parseFloat(id.dr), 0);

  return (
    <View wrap={false} style={styles.page}>
      <View style={styles.watermark}>
        <Text style={styles.watermarkText}>{label}</Text>
      </View>
      <View style={{ width: "100%", borderBottom: "2px solid #003311" }}></View>
      <View style={{ width: "100%", flexDirection: "row", marginTop: "-20px" }}>
        <Text
          style={{
            backgroundColor: data[0]?.printed ? "red" : "#003311",
            color: "#fff",
            paddingTop: 6,
            marginLeft: "35%",
            paddingBottom: 6,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 2,
            marginBottom: 10,
          }}
        >
          ONLINE TREASURY RECEIPT
        </Text>
      </View>
      {/* <View> <img src={qrcode} style={styles.qrcodeImage} /></View> */}
      <View style={{ flexDirection: "row", marginBottom: 10 }}>
        <View style={{ width: "50%" }}>
          <View style={styles.itemContainer}>
            <Text>Ref No.:</Text>
            <Text>{data[0]?.reference_number}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Paid By:</Text>
            <Text>{data[0]?.tax_payer}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>KNID/Tax ID:</Text>
            <Text>{data[0]?.user_id || data[0]?.kn_id}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>{data[0]?.tax_parent_code}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Revenue Head:</Text>
            <Text>{data[0]?.description}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Status:</Text>
            <Text>
              <Badge >{data[0]?.status}</Badge>
            </Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Payment Validation:</Text>
            <Text>{data[0]?.logId}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Amount:</Text>
            <Text>N{formatNumber(total)}</Text>
          </View>
        </View>
        <View style={{ width: "50%" }}>
          <View style={styles.itemContainer}>
            <Text>Amount in Words:</Text>
            <Text>
              {toWordsconver(total.toString().split(".")[0])?.toUpperCase()}
              NAIRA
              {total.toString().split(".")[1] > 0
                ? ` AND ${toWordsconver(
                  total.toString().split(".")[1]
                )} kobo`.toUpperCase()
                : null}{" "}
              ONLY
            </Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>MDA:</Text>
            <Text>{data[0]?.mda_name}</Text>
          </View>
          <View style={styles.itemContainer}>
            <Text>Payment Period:</Text>
            <Text>
              {data[0]?.date_from && data[0]?.date_to
                ? `${moment(data[0]?.date_from).format(
                  "D MMMM, YYYY"
                )} - ${moment(data[0]?.date_to).format("D MMMM, YYYY")}`
                : moment(data[0]?.transaction_date).format("D MMMM, YYYY")}
            </Text>
          </View>
          {data[0]?.printed ? (
            <View>
              {" "}
              <View style={styles.itemContainer}>
                <Text>Printed On:</Text>
                <Text>{moment(data[0]?.printed_at).format("DD/MM/YYYY")}</Text>
              </View>
              <View style={styles.itemContainer}>
                <Text>Printed By:</Text>
                <Text>{data[0]?.printed_by}</Text>
              </View>
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text
                  style={{
                    ...styles.itemContainer,
                    textAlign: "center",
                    color: "red",
                  }}
                >{`This Receipt has been printed for ${data[0]?.printed} (time)s`}</Text>
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default ReceiptPreview;
