import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Image,
  Container,
  Button,
} from "@themesberg/react-bootstrap";
import { useHistory } from "react-router-dom";
import { Routes } from "../routes";
import ReactHero from "../assets/img/kigra.jpg";
import logo from "../assets/img/kirmas.png";
import NonTaxImage from "../assets/img/non-tax.png";
import TaxImage from "../assets/img/tax-img.jpg";
import MotorLicense from "../assets/img/motor-license.jpeg";
import LgaPaymentsLogo from "../assets/img/lga-payments.jpg";
import LandPayments from "../assets/img/pay-land.jpg";
import "../pages/examples/index.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/action/auth";
import Contact from "../contact/Contact";
import { Alert, Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useQuery } from "../utils";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import { AiOutlinePoweroff } from "react-icons/ai";

const SECTORS_ARRAY = [
  {
    id: 0,
    title: "TAX PAYMENTS",
    image: TaxImage,
    description:
      "Calculate your tax, generate tax invoice and make payment online",
    route: Routes.TaxPayment.path,
  },
  {
    id: 1,
    title: "NON-TAX PAYMENTS",
    image: NonTaxImage,
    description:
      "Generate Non-tax invoice for Ministry, Departments and Agencies",
    route: Routes.NonTaxPayment.path,
  },
  {
    id: 2,
    title: "MOTOR LICENSE OFFICE",
    image: MotorLicense,
    description: "General vehicles license and registration.",
    route: Routes.VehiclePayments.path,
  },
  {
    id: 3,
    title: "Local Government Revenue".toUpperCase(),
    image: LgaPaymentsLogo,
    description: "Pay L.G.A. Revenue",
    route: Routes.LgaPayments.path,
  },
  {
    id: 4,
    title: "Land use charges".toUpperCase(),
    image: LandPayments,
    description: "MINISTRY OF LAND AND PHYSICAL PLANNING",
    route: Routes.LandPayments.path,
  },
];
export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const display = query.get("display");
  const Feature = (props) => {
    const {
      title,
      description,
      image = NonTaxImage,
      onClick = (f) => f,
    } = props;

    return (
      <Col
        xs={6}
        sm={6}
        lg={4}
        style={{
          padding: "1.5rem",
          margin: isPhoneSize && -15,
        }}
      >
        <Card
          className="bg-white text-white rounded"
          style={{
            borderWidth: 0,
            cursor: "pointer",
            height: isPhoneSize ? "160px" : "100%",
          }}
          onClick={onClick}
        >
          <img
            className="rounded"
            style={{
              // opacity: .9,
              position: "absolute",
              left: 0,
              top: 0,
              width: "90%",
              height: isPhoneSize ? "90%" : "100%",
            }}
            src={image}
            alt=""
          />
          <div
            className="px-lg-3 px-lg-4 py-5 text-center rounded"
            style={{
              position: "relative",
              backgroundColor: "rgba(0,0,0,.4)",
              width: "90%",
              height: "90%",
            }}
          >
            {/* <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span> */}
            <h5 className="fw-bold text-white">{title}</h5>
            <p className="fw-bold">{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const currentYear = moment().get("year");
  const divStyle = {
    backgroundImage: `url(${logo})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "8rem",
  };
  const { user, tax_account, tax_accounts = [] } = useSelector((s) => s.auth);
  const [open, setOpen] = useState(display ? true : false);
  const toggle = () => setOpen(!open);

  function isObjectEmpty(obj) {
    return obj === null || obj === undefined;
  }

  const isPhoneSize = useIsPhoneSize();
  const firstname = user?.name?.split(" ")[0];

  return (
    <>
      <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Tax Payer Info.</ModalHeader>
        {isObjectEmpty(tax_account) ? (
          <Alert>
            <Button size="sm" onClick={() => history.goBack()}>
              Back
            </Button>
            <center>Tax payer not found, please try again later</center>
          </Alert>
        ) : (
          <div className="p-2">
            <Button size="sm" onClick={() => history.goBack()}>
              Back
            </Button>
            <h4>
              Name: <b>{tax_account.org_name || tax_account.name}</b>
            </h4>
            <h4>
              Email: <b>{tax_account.email}</b>
            </h4>
            <h4>
              Tax ID: <b>{tax_account.taxID}</b>
            </h4>
          </div>
        )}
      </Modal>
      <section className=" p-3  bg-grey text-white">
        {!isPhoneSize && (
          <Row className="m-0 p-0">
            <Col
              style={{ marginLeft: 5 }}
              onClick={() => history.push("/user/dashboard/profile")}
            >
              <img
                src={require("../images/Ahmad-removebg-preview.png")}
                alt="profile"
                style={{ width: isPhoneSize ? 40 : "60px", cursor: "pointer" }}
                className="ml-5 img-fluid"
              />

              <h6 className="text-dark d-block mr-5" style={{ marginLeft: 5 }}>
                Profile
              </h6>
            </Col>
            <Col>
              <div className="d-flex" style={{ float: "right" }}>
                {/* <h3 className="text-dark">
                  Welcome Back, {user?.org_name || firstname}
                </h3> */}
                <div
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => dispatch(logout(history))}
                  className="text-danger text-center"
                >
                  <AiOutlinePoweroff size={25} /> <br />
                  Logout
                </div>
              </div>
            </Col>
          </Row>
        )}
        <br />
        <Container>
          <div
            className="mb-1 mb-lg-1"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              lg={3}
              md={3}
              sm={3}
              className="text-center"
              style={{ width: "20%" }}
            >
              <Image
                src={require("../assets/img/kano-govt.png")}
                height={isPhoneSize ? 80 : 150}
                width={isPhoneSize ? 80 : 150}
                className="d-block mx-auto mb-lg-3 bg-white img-fluid"
                alt="Kano state govt logo"
                style={{
                  borderRadius: "100px",
                }}
              />
            </div>
            <div
            // lg={4}
            // md={4}
            // sm={4}
            >
              {isPhoneSize ? (
                <p className="text-dark p-lg-5 p-1 text-center">
                  {"Kano Integrated Revenue Management and Assurance System (KIRMAS)".toUpperCase()}
                </p>
              ) : (
                <h4 className="text-dark p-lg-5 p-1 text-center">
                  {"Kano Integrated Revenue Management and Assurance System (KIRMAS)".toUpperCase()}
                </h4>
              )}
            </div>
            <div className="text-center" style={{ width: "20%" }}>
              <Image
                src={ReactHero}
                height={isPhoneSize ? 80 : 150}
                width={isPhoneSize ? 80 : 150}
                className="d-block mx-auto mb-lg-3 img-fluid"
                alt="KIRMAS logo"
              />
            </div>
          </div>

          <Row
            className="align-items-center justify-content-center my-0"
            style={{
              margin: isPhoneSize && -45,
            }}
          >
            {/* <b className="text-dark">{JSON.stringify(user)}</b> */}
            {SECTORS_ARRAY.map((feature) => (
              <Feature
                key={`features-${feature.id}`}
                {...feature}
                onClick={() => history.push(feature.route)}
              />
            ))}
          </Row>
          <Contact />
        </Container>
      </section>
      <footer className="py-3 m-0">
        <div className="p-0 m-0" style={divStyle}>
          <center>
            <p className="mb-0 text-center pt-5 mt-5">
              © {`${currentYear} `}
              <Card.Link
                href="https://kirmas.gov.ng"
                target="_blank"
                className="text-blue text-decoration-none fw-normal"
              >
                KIRMAS
              </Card.Link>
            </p>
          </center>
        </div>
      </footer>
    </>
  );
};
