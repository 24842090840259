import React, { useState, useEffect, useCallback, useRef } from "react";
import { Col, Modal, Row } from "@themesberg/react-bootstrap";
import { _fetchApi } from "../../redux/action/api";
import { SearchBar, SelectInput } from "../../components/UI";
import TaxDropDown from "./components/TaxDropdown";
import PaymentsContainer from "./components/PaymentsContainer";
import { formatNumber, toCurrency } from "../../utils";
import { Button, Label } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { WelcomeMessage } from "./LandPayments";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { TAX_ACCOUNT } from "../../redux/action/type";
import CustomTypeahead from "../../components/UI/CustomTypeahead";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DaterangeSelector from "../dashboard/DaterangeSelector";
// import moment from "moment";
import toast from "react-hot-toast";
// import ExpressAcct from "../ExpressAccount";
// import { SelectInput1 } from "../components/SelectInput";
import AddAccout from "../AddAccount";

export const convertToMenu = (data, rootData) => {
  // alert(JSON.stringify(data));
  const buildMenu = (item) => {
    const children = data.filter(
      (child) => child.tax_parent_code === item.tax_code
    );
    if (children?.length === 0) {
      return item;
    } else {
      return {
        ...item,
        // has_child: true,
        has_child: children.map(buildMenu).some(child => isNaN(child.tax_fee)),
        children: children.map(buildMenu),
      };
    }
  };

  if (!rootData) {
    // If rootData is not provided, try to find the root item automatically
    const findRootItem = () => {
      for (const item of data) {
        if (!item.tax_parent_code) {
          return item;
        }
      }
      return null;
    };

    rootData = findRootItem();
  }

  if (!rootData) {
    return [];
  }

  return buildMenu(rootData).children;
};

function NonTaxPayments() {
  const _form = {
    page_title: "NON TAX PAYMENTS",
    rows: [],
    economic_code: "",
    title: "",
    tax_code: "",
    tax_parent_code: "",
    taxes: "",
    parent: "",
    sector: "NON TAX",
    from: "",
    to: "",
  };

  const [tax_payers, setTaxPayers] = useState([]);
  const { tax_account, taxaccounts = [], user } = useSelector((p) => p.auth);
  const [tax_payer, setTapayer] = useState("");
  const [form, setForm] = useState(_form);
  const [filterText, setFilterText] = useState("");
  const [taxList, setTaxList] = useState([]);
  const [subTaxList, setSubTaxList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [filtered_taxList, setFilteredTaxList] = useState([]);
  const dispatch = useDispatch();
  const ref = useRef();

  const [showDefault, setShowDefault] = useState(false);
  const toggleModal = () => setShowDefault(!showDefault);
  const handleFilterChange = (e) => {
    setFilterText(e);
  };
  const handleFocus = (name) => {
    setTapayer(name);
    setTimeout(() => {
      toast.success("You can now close and search the tax payer by name or ID");
      toggleModal();
      // myRef.current.focus();
    }, 2000);
  };
  // let active_list =  filtered_taxList.length? filtered_taxList: taxList;

  // Manually provide the rootData if not present in the data array
  const rootData = taxList.find(
    (item) => item.tax_code === "Authorized" && item.sector === "NON TAX"
  );
  //   const menuData = convertToMenu(data, rootData);
  //   console.log(menuData);
  const rearrangedAuthorized = convertToMenu(taxList, rootData);

  // Manually provide the rootData if not present in the data array
  const rootData2 = taxList.find(
    (item) => item.tax_code === "Unauthorized" && item.sector === "NON TAX"
  );
  //   const menuData = convertToMenu(data, rootData);
  //   console.log(menuData);

  const rearrangedUnuthorized = convertToMenu(taxList, rootData2);

  const rearrangedArray = [
    {
      id: 587883,
      title: "Authorized",
      tax_code: "Authorized",
      tax_parent_code: "NON TAX",
      children: rearrangedAuthorized,
    },
    {
      id: 8784399,
      title: "Unauthorized",
      tax_code: "Unauthorized",
      tax_parent_code: "NON TAX",
      children: rearrangedUnuthorized.filter(
        (item) => item.sector === "NON TAX"
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      "/kigra-taxes?query_type=select-heads&sector=NON TAX",
      (resp) => {
        if (resp.result.length) {
          setTaxList(resp.result);
        } else {
          setTaxList([]);
        }
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const getSubTaxList = useCallback((tax_code) => {
    setSubTaxList([]);
    setLoading1(true);
    if (tax_code) {
      _fetchApi(
        `/kigra-taxes?query_type=select-sub&tax_parent_code=${tax_code}&sector=NON TAX`,
        (resp) => {
          if (resp?.result?.length) {
            setSubTaxList(
              resp.result.map((item) => ({
                ...item,
                checked: item.default === "checked",
                amount: 0,
              }))
            );
            setLoading1(false);
          }
          setLoading1(false);
        },
        (err) => {
          console.error(err);
          setLoading1(false);
          // setForm(_form);
          setSubTaxList([]);
        }
      );
    }
  }, []);

  useEffect(() => {
    getSubTaxList(form.tax_code);
  }, [getSubTaxList, form.tax_code]);

  const handleTaxRateChenged = (e, tax) => {
    const { value } = e.target;
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          sqr_meter: value,
          amount: parseFloat(value <= 0 ? 1 : value) * Math.max(tax.tax_fee, 1),
          checked: parseInt(value) > 0 ? true : false,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };
  // const handleTaxFeeChecked = ({ target: { name, value } }, item) => {
  //   let updatedItemsArray = [...selectedItems]; // Create a copy of the selectedItems array

  //   const selectedItemIndex = updatedItemsArray.findIndex(
  //     (selectedItem) => selectedItem.id === item.id
  //   );

  //   if (selectedItemIndex !== -1) {
  //     // Item is already in selectedItems, so remove it
  //     updatedItemsArray.splice(selectedItemIndex, 1);
  //   } else {
  //     // Item is not in selectedItems, so add it
  //     updatedItemsArray.push(item);
  //   }

  //   // Update the form state
  //   setForm((prevState) => ({
  //     ...prevState,
  //     [name]: updatedItemsArray
  //       .map((selectedItem) => selectedItem.id)
  //       .join(", "),
  //   }));

  //   // Update the selectedItems state
  //   setSelectedItems(updatedItemsArray);
  // };

  const handleTaxFeeChecked = (tax) => {
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: item.tax_fee || item.amount,
          sqr_meter: (item.checked && item.sqr_meter) || 1,
          checked: !item.checked,
          department: form.department ? form.department : null,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };

  const handleQty = ({ target: { value } }, item) => {
    const updatedSubTaxList = subTaxList.map((_item) => {
      if (item.id === _item.id) {
        return {
          ..._item,
          checked: true,
          qty: value,
          amount: parseInt(value) * _item.tax_fee, // Reset amount to 0 if unchecked
        };
      } else {
        return _item;
      }
    });

    const checkedTaxIDs = updatedSubTaxList
      .filter((_item) => _item.checked)
      .map((_item) => _item.id)
      .join(", ");

    setSubTaxList(updatedSubTaxList);

    setForm((prevState) => ({
      ...prevState,
      taxes: checkedTaxIDs,
    }));
  };

  // Function to check if an object contains the searchText in title or tax_code properties
  const containsFilterTexts = useCallback(
    (obj) => {
      if (searchText.length) {
        return (
          obj?.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
          obj?.tax_code?.toLowerCase().includes(searchText?.toLowerCase())
        );
      }
    },
    [searchText]
  );

  // Update the filtered_taxList state when searchText changes
  useEffect(() => {
    const updatedFilteredTaxList = [];

    // Iterate through the data array and filter based on searchText
    for (const item of taxList) {
      if (containsFilterTexts(item)) {
        updatedFilteredTaxList.push({ ...item, children: [] });
      }
    }

    if (searchText && searchText.length > 0) {
      setFilteredTaxList(updatedFilteredTaxList);
    } else {
      setFilteredTaxList([]);
    }
  }, [containsFilterTexts, searchText, taxList]);

  const filteredTaxList = filterText.length
    ? subTaxList.filter((item) => {
      return (
        // item.economic_code
        //   .includes(filterText) ||
        item.title.toLowerCase().includes(filterText.toLowerCase())
      );
    })
    : subTaxList;

  const SELECTED_TAX_ITEMS = subTaxList.some((tax) => tax.amount > 0)
    ? subTaxList.filter((tax) => tax.amount > 0)
    : [];
  const isPhoneSize = useIsPhoneSize();
  const [open, setOpen] = useState(isPhoneSize ? true : false);
  const openModal = () => setOpen(isPhoneSize ? !open : false);

  const getTaxPayers = useCallback(() => {
    if (tax_payer.length > 2) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    } else if (tax_payer.length === 0) {
      _fetchApi(
        `/users/search-tax-payers?user_id=${tax_payer}&max=50`,
        (resp) => {
          if (resp.success) {
            setTaxPayers(resp.data);
          }
        }
      );
    }
  }, [tax_payer]);

  useEffect(() => {
    getTaxPayers();
  }, [getTaxPayers]);

  const handleDateRangeChange = ({ target: { name, value } }) => {
    if (name === "from") {
      setForm((p) => ({ ...p, from: value }));
    } else if (name === "to") {
      setForm((p) => ({ ...p, to: value }));
    }
  };
  const handleAddList = (cb = (f) => f) => {
    if (!form.from || !form.to) {
      toast.error("Please select date");
    } else {
      // setForm(_form);
      cb();
    }
  };

  const handleAmountChange = (e, tax) => {
    let arr = [];
    subTaxList.forEach((item) => {
      if (item.id === tax.id) {
        arr.push({
          ...item,
          amount: e.target.value,
          checked: parseInt(e.target.value) > 0 ? true : false,
          department: form.department ? form.department : null,
        });
      } else {
        arr.push(item);
      }
    });
    setSubTaxList(arr);
  };

  return (
    <PaymentsContainer
      handleAddList={handleAddList}
      open={open}
      openModal={openModal}
      form={form}
      handleTaxFeeChecked={handleTaxFeeChecked}
      search={
        <SearchBar
          filterText={searchText}
          onFilterTextChange={(val) => setSearchText(val)}
          placeholder="Search by tax name"
          className="bg-dark text-white"
          style={{ marginTop: 0 }}
        />
      }
      // handleAddList=
      menu={
        loading ? (
          <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
            <p>
              <Skeleton count={10} height={50} />
            </p>
          </SkeletonTheme>
        ) : (
          <TaxDropDown
            form={form}
            setForm={setForm}
            data={filtered_taxList.length ? filtered_taxList : rearrangedArray}
            openModal={openModal}
          />
        )
      }
      selected_taxes={SELECTED_TAX_ITEMS.map((itm) => ({
        ...itm,
        tax_fee: itm.amount,
        sector: form.sector,
      }))}
    >
      {/* {JSON.stringify({ rearranged, USER: store.getState().auth.user })} */}
      {form.tax_code === "" ? (
        <WelcomeMessage />
      ) : loading1 ? (
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
          <p>
            <Skeleton count={10} height={45} />
          </p>
        </SkeletonTheme>
      ) : (
        <Row className="m-0">
          <Col md={12}>
            <DaterangeSelector
              from={form.from}
              to={form.to}
              handleChange={handleDateRangeChange}
            />
            {/* {JSON.stringify(form)} */}
            {user.role !== "user" ? (
              <Row>
                <Col md="8">
                  <Row>
                    <Col md={8}>
                      <CustomTypeahead
                        onInputChange={(e) => {
                          setTapayer(e);
                          console.log(e, "TYPED");
                        }}
                        labelKey={(e) =>
                          `${e.account_type === "org" ? e.org_name : e.name}(${e.taxID
                          }) ${e.phone ? `:${e.phone || e.office_phone}` : ""}`
                        }
                        onChange={(e) => {
                          if (e && e[0]?.id) {
                            dispatch({
                              type: TAX_ACCOUNT,
                              payload: e[0],
                            });
                          }
                        }}
                        clearButton
                        col={12}
                        label={`Select Tax payer`}
                        options={tax_payers}
                        placeholder="Search Name | TaxID | Phone No."
                      />
                    </Col>
                    <Col md={4} className="pt-2">
                      <Button
                        onClick={() => {
                          // alert(showDefault)
                          toggleModal();
                        }}
                        className="bg-white mt-4 ml--2 text-dark text-xs"
                      >
                        <FontAwesomeIcon icon={faPlus} /> Create Tax payer
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Label>Tax Payer</Label>{" "}
                <SelectInput
                  options={taxaccounts.map((item) => [
                    item.org_name || item.name
                  ])}
                  defaultValue={tax_account.org_name || tax_account.name}
                  value={
                    tax_account.org_name || tax_account.name
                  }
                  name="tax_payer"
                  onChange={(e) => {
                    console.log(e.target.value);
                    let val = e.target.value;
                    let sel = taxaccounts.filter(
                      (item) => item.org_name == val || item.name == val
                    );
                    console.log(sel);
                    dispatch({
                      type: TAX_ACCOUNT,
                      payload: sel[0],
                    });
                  }}
                />
              </>
            )}
          </Col>
          <br />
          <div className="mb-2">
            <SearchBar
              filterText={filterText}
              onFilterTextChange={handleFilterChange}
              placeholder="Search tax"
            />
          </div>
          <Col md={12} sm={12} lg={12} className="bg-white">
            {form?.mda_name?.includes("KNUPDA") ? (
              <table
                className="table-responsive"
                style={{ width: "100%", border: "1px solid black" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        borderWidth: 1,
                        borderColor: "black",
                        fontWeight: "bold",
                        margin: "50px",
                      }}
                      className=" m-5 p-2"
                      width="5%"
                    >
                      <center> SN</center>
                    </th>
                    <th
                      style={{
                        borderWidth: 1,
                        borderColor: "black",
                        fontWeight: "bold",
                      }}
                      width="40%"
                      className="m-4"
                    >
                      <center> Description</center>
                    </th>

                    <th
                      style={{
                        borderWidth: 1,
                        borderColor: "black",
                        fontWeight: "bold",
                      }}
                      width="20%"
                      className="text-right"
                    >
                      <center> Amount</center>
                    </th>

                    <th
                      style={{
                        borderWidth: 1,
                        borderColor: "black",
                        fontWeight: "bold",
                      }}
                      width="15%"
                      className="text-right"
                    >
                      <center> Action</center>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTaxList?.map((item, idx) => (
                    <tr key={idx}>
                      <td
                        className="text-center"
                        style={{ borderWidth: 1, borderColor: "black" }}
                      >
                        {idx + 1}
                      </td>
                      <td
                        style={{ borderWidth: 1, borderColor: "black" }}
                        className="m-5 p-2"
                      >
                        {item.title}{" "}
                        {item.default || item.uom ? (
                          <span>
                            (
                            {item.default ? `₦${toCurrency(item.default)}` : ""}{" "}
                            {item.uom})
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        className="text-right m-5 p-2"
                        style={{ borderWidth: 1, borderColor: "black" }}
                      >
                        <input
                          value={form.amount || item.amount}
                          onChange={(e) => {
                            handleAmountChange(e, item);
                          }}
                          type="number"
                          style={{ width: "80px" }}
                          name="tax_fee"
                          className="p-0 m-0 text-center"
                        />
                      </td>
                      <td
                        style={{ borderWidth: 1, borderColor: "black" }}
                        className="text-center"
                      >
                        <input
                          className="large-check"
                          onChange={(e) => handleTaxFeeChecked(item)}
                          checked={item.checked}
                          value={item.id}
                          type="checkbox"
                          name="taxes"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <table
                className="table- responsive table-zebra"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th
                      className="text-center"
                      style={{ borderWidth: 1, borderColor: "black" }}
                      width="5%"
                    >
                      SN
                    </th>
                    <th
                      className="text-center"
                      style={{ borderWidth: 1, borderColor: "black" }}
                      width="60%"
                    >
                      Title
                    </th>
                    <th
                      className="text-center"
                      width="15%"
                      style={{ borderWidth: 1, borderColor: "black" }}
                    >
                      Amount (₦){" "}
                    </th>
                    {filteredTaxList.some((tax) =>
                      tax?.uom?.includes("per")
                    ) ? (
                      <th
                        className="text-center"
                        width="20%"
                        style={{ borderWidth: 1, borderColor: "black" }}
                      >
                        {filteredTaxList.some((tax) => tax.uom === "per item")
                          ? "QTY"
                          : "Meter"}
                      </th>
                    ) : null}
                    <th
                      style={{ borderWidth: 1, borderColor: "black" }}
                      width="5%"
                      className="text-right"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTaxList?.map((item, idx) => (
                    <tr key={idx}>
                      <td
                        className="text-center"
                        style={{ borderWidth: 1, borderColor: "black" }}
                      >
                        {idx + 1}
                      </td>
                      <td
                        style={{ borderWidth: 1, borderColor: "black" }}
                        className="m-3 p-2"
                      >
                        {item.title}
                      </td>

                      <td
                        className="text-right m-5 p-2"
                        style={{ borderWidth: 1, borderColor: "black" }}
                      >
                        {item.tax_fee <= 0 ? (
                          <input
                            value={item.amount}
                            onChange={(e) => {
                              handleAmountChange(e, item);
                            }}
                            type="number"
                            style={{ width: "80px" }}
                            name="tax_fee"
                            className="p-0 m-0 text-center"
                          />
                        ) : item.amount ? (
                          formatNumber(item.amount)
                        ) : (
                          formatNumber(item.tax_fee)
                        )}
                      </td>

                      {filteredTaxList.some((tax) => tax.uom === "per item") ? (
                        <td
                          className="text-right m-5 p-2"
                          style={{ borderWidth: 1, borderColor: "black" }}
                        >
                          {item.uom === "per item" ? (
                            <input
                              style={{ width: "50px" }}
                              onChange={(e) => handleQty(e, item)}
                              type="number"
                              name="taxes"
                            />
                          ) : null}
                        </td>
                      ) : null}

                      <td
                        style={{ borderWidth: 1, borderColor: "black" }}
                        className="text-center"
                      >
                        <input
                          className="large-check"
                          onChange={(e) => handleTaxFeeChecked(item)}
                          checked={item.checked}
                          value={item.id}
                          type="checkbox"
                          name="taxes"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      )}
      <Modal as={Modal.Dialog} centered show={showDefault} size="lg"
        style={{ zIndex: showDefault ? 9999 : 1 }}>
        <Modal.Header>
          <Modal.Title className="h6">EXPRESS TAX PAYER FORM</Modal.Title>
          <Button
            variant="danger"
            className="bg-danger text-white"
            aria-label="Close"
            onClick={toggleModal}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <AddAccout isModal={true} handleFocus={handleFocus} />
        </Modal.Body>
      </Modal>
    </PaymentsContainer>
  );
}

export default NonTaxPayments;
