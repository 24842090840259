import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Col,
  Row,
  Card,
  Spinner,
  Button,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { _fetchApi, _postApi } from "../redux/action/api";
import { CounterWidget } from "../components/Widgets";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { TransactionLogs } from "../components/Tables2";
import CustomTypeahead from "../components/UI/CustomTypeahead";
import { useSelector } from "react-redux";
import CustomDateRange from "./dashboard/CustomDateRange";
import PaymentReciptPdf from "./PaymentReciptPdf";
import { downloadExcel } from "react-export-table-to-excel";
import ReceiptLogsPDF from "../components/ReceiptLogsPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Input } from "reactstrap";

function ReceiptReport() {
  const { tax_account, user } = useSelector((s) => s.auth);
  const tableRef = useRef(null);
  const [refNo, setRef] = useState(null);

  // date picker setup
  const today = moment();
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today.format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const weekStart = moment().startOf("week").format("YYYY-MM-DD");
    const weekEnd = moment().endOf("week").format("YYYY-MM-DD");
    const monthStart = moment().startOf("month").format("YYYY-MM-DD");
    const monthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const yearStart = moment().startOf("month").format("YYYY-MM-DD");
    const yearEnd = moment().endOf("month").format("YYYY-MM-DD");

    const qStart = moment(
      `'${dateRange.year}-${dateRange.quarter * 3 - 3}-01`
    ).format("YYYY-MM-DD");
    const qEnd = moment(`'${dateRange.year}-${dateRange.quarter * 3}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    switch (dateRange.type) {
      case "daily":
        setDateRange({ ...dateRange, from: today, to: today });
        break;
      case "weekly":
        setDateRange({ ...dateRange, from: weekStart, to: weekEnd });
        break;
      case "monthly":
        setDateRange({ ...dateRange, from: monthStart, to: monthEnd });
        break;
      case "quarterly":
        setDateRange({ ...dateRange, from: qStart, to: qEnd });
        break;
      case "yearly":
        setDateRange({ ...dateRange, from: yearStart, to: yearEnd });

      default:
        break;
    }
  }, [dateRange.type]);

  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [userslist, setUserslist] = useState([]);
  const [form, setForm] = useState({
    query_type: "details-by-date",
    limit: 200,
    offset: 0,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPrints, setTotalPrints] = useState(0);
  const gotoPage = (x) => {
    setForm((prevForm) => ({
      ...prevForm,
      offset: x * form.limit,
    }));
  };
  const currentPage = Math.ceil((form.offset + 1) / form.limit);
  // const [dailySummary, setDailySummary] = useState({});

  const [selectedUser, setSelectedUser] = useState({});
  const agent = useSelector((state) => state.auth.user);
  useEffect(() => {
    _postApi(
      `/transactions/update-print-count`,
      {
        query_type:
          selectedUser && selectedUser.id ? "summary_by_user" : "summary",
        from: dateRange.from,
        to: dateRange.to,
        user_id: selectedUser.id,
        sector: agent.sector,
      },
      (resp) => {
        if (resp && resp.data && resp.data.length) {
          // setDailySummary(resp.data[0]);
        }
      }
    );
  }, [dateRange.from, dateRange.to, selectedUser.id]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / form.limit))

  }, [form.limit, totalItems])

  useEffect(() => {
    // if dateRange changes
    if (dateRange.from && dateRange.to) {
      setForm((p) => ({
        ...p, limit: p.limit,
        offset: 0,
      }))
    }
  }, [dateRange])

  useEffect(() => {
    _fetchApi(
      `/users/get-admins?mda_code=${user.mda_code ? user.mda_code : ""}`,
      (resp) => {
        // console.log(resp.data)
        setUserslist(resp.data);
      },
      () => {
        // toast.error("Error: Failed to submit");
      }
    );
  }, [user.mda_code]);

  // useEffect(() => {
  //   _postApi(`/transactions/update-print-count`, {query_type: 'all_summary'}, resp => {
  //     if(resp && resp.data && resp.data.length) {
  //       setAllSummary(resp.data[0])
  //     }
  //   })
  // }, [])

  useEffect(() => {
    setLoading(true);
    _postApi(
      `/transactions/update-print-count?sector=${user.sector}'`,
      {
        query_type: form.query_type,
        from: dateRange.from,
        to: dateRange.to,
        user_id: selectedUser.id,
        user_name: selectedUser.name,
        sector: agent.sector,
        limit: form.limit,
        offset: form.offset,
      },
      (resp) => {
        setLoading(false);
        if (resp && resp.data) {
          setReport(resp.data);
          setTotalItems(resp.data[0]?.total_rows);
          setTotalRevenue(resp.data[0]?.total_revenue);
          setTotalPrints(resp.data[0]?.total_prints);

        }
      },
      (err) => {
        setLoading(false);
        console.log(err);
        setReport([]);
        setTotalItems(0);
      }
    );


  }, [dateRange.from, dateRange.to, selectedUser, form]);

  // const totalCount = report.length;

  const payer = tax_account;

  const header = [
    "SN",
    "DATE",
    "Ref No.",
    "TAX PAYER NAME",
    "DESCRIPTION OF PAYMENT",
    "AMOUNT",
    "STATUS",
  ];
  const RECORDS = report?.length
    ? report
      ?.map((t) => ({
        ...t,
        amount: Math.max(t.cr, t.dr),
      }))
      .filter((_) => _.amount)
      .map((item, idx) => ({
        id: idx + 1,
        date: item.transaction_date,
        ref_no: item.reference_number,
        tax_payer: item.tax_payer,
        description: item.description,
        amount: Math.max(item.dr),
        status: item.status,
      }))
    : [];
  function handleDownloadExcel() {
    downloadExcel({
      fileName: "kirmas-user_ledger",
      sheet: "user_ledger",
      tablePayload: {
        header,
        body: RECORDS.filter((_) => _.amount > 0),
      },
    });
  }

  return (
    <div>
      {refNo ? (
        <Row
          className="w-100"
          style={{ border: "1px solid black", height: "1000px !important" }}
        >
          <Col
            md={12}
            className="w-100 h-100"
            style={{ height: "1000px !important" }}
          >
            <Button onClick={() => setRef(null)} className="btn-warning">
              Close
            </Button>
            <PaymentReciptPdf refNo={refNo} />
          </Col>
        </Row>
      ) : (
        <>
          <div className="">
            <h4 className="my-4 fw-bold text-center">
              Receipt Generation Report
            </h4>
          </div>
          <div className="my-1">
            <CustomDateRange
              type={dateRange.type}
              month={dateRange.month}
              quarter={dateRange.quarter}
              year={dateRange.year}
              from={dateRange.from}
              to={dateRange.to}
              handleChangeDate={handleChangeDate}
            />
          </div>

          <Row>
            <Col md={6}>
              <Card className="bg-white p-2">
                <CounterWidget
                  color="lightblue"
                  category="Number of Prints"
                  count={totalPrints}
                  period="Feb 1 - Apr 1"
                  percentage={18.2}
                  icon={faPrint}
                  iconColor="shape-secondary"
                  showChanges={false}
                // onClick={() => history.push(Routes.ReceiptReportDetails.path+`?from=${dateRange.from}&to=${dateRange.to}`)}
                />
              </Card>
            </Col>
            <Col md={6}>
              <Card className="bg-white p-2">
                <CounterWidget
                  color="lightblue"
                  category="Total revenue"
                  title={totalRevenue}
                  period="Feb 1 - Apr 1"
                  percentage={18.2}
                  icon={faPrint}
                  iconColor="shape-secondary"
                  showChanges={false}
                // onClick={() => history.push(Routes.ReceiptReportDetails.path)}
                />
              </Card>
            </Col>
          </Row>

          <Card>
            <Card.Body>
              {loading && (
                <div>
                  <Spinner />
                  Loading...
                </div>
              )}
              <Row className="d-flex  flex-direction-row justify-content-between align-items-end">
                <Col md={8}>
                  <h4 className="my-1 fw-bold">
                    RECEIPTS PRINTED
                    {totalItems ? (
                      <Row>
                        <Col md={3}>
                          <Input
                            placeholder="Items page"
                            type="select"
                            width={"100px"}
                            value={form.limit}
                            onChange={({ target: { value } }) =>
                              setForm((p) => ({
                                ...p,
                                limit: parseInt(value) - 1,
                                offset: value * (currentPage - 1),
                              }))
                            }
                          >
                            {[100, 200, 300, 400, 500].map((lmt, idx) => (
                              <option key={idx} value={lmt}>
                                {lmt} / page
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col>
                          {Array.from({ length: totalPages }).map((_, page) => (
                            <Button
                              size="sm"
                              className={
                                currentPage === page + 1
                                  ? "bg-light text-dark"
                                  : ""
                              }
                              onClick={() => gotoPage(page)}
                            >
                              {page + 1}
                            </Button>
                          ))}
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </h4>
                </Col>
                <Col className="text-right mb-2">
                  <Row>
                    <Col md={4}>
                      <ButtonGroup>
                        <PDFDownloadLink
                          document={
                            <ReceiptLogsPDF
                              user={user}
                              data={report
                                .map((r) => ({ ...r, amount: r.dr }))
                                .filter((_) => _.amount > 0)}
                            />
                          }
                          fileName={`"Receipt-Report"-${moment().format(
                            "YYYYMMDDhhmm"
                          )}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <Button
                                variant="outline-primary"
                                size="sm"
                                style={{ borderRadius: "10px 0px 0px 10px" }}
                              >
                                PDF
                              </Button>
                            )
                          }
                        </PDFDownloadLink>
                        <Button
                          variant={
                            dateRange.view !== "all"
                              ? "outline-primary"
                              : "primary"
                          }
                          size="sm"
                          onClick={handleDownloadExcel}
                        >
                          Excel
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col md={6}>
                      {user.rank === "Department Head" ? (
                        <CustomTypeahead
                          placeholder="Select User"
                          className="w-100 mb-1"
                          options={userslist}
                          col={12}
                          labelKey={(i) => `${i.name}`}
                          onChange={(e) => {
                            if (e.length) {
                              setSelectedUser(e[0]);
                              setForm((p) => ({
                                ...p,
                                query_type: "details-by-date-and-user",
                              }));
                            }
                          }}
                        />
                      ) : null}
                    </Col>

                  </Row>
                </Col>
              </Row>
              <TransactionLogs
                setRef={setRef}
                viewOnly={false}
                _ref={tableRef}
                data={report
                  ?.map((t) => ({
                    ...t,
                    amount: Math.max(t.cr, t.dr),
                  }))
                  .filter((_) => _.amount)}
              />
            </Card.Body>
          </Card>
        </>
      )}
      {/* {JSON.stringify(user)} */}
    </div>
  );
}

export default ReceiptReport;
