import React from "react";
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../assets/img/kano.png";
import logo1 from "../assets/img/kigra.jpg";
import { formatNumber, toNairaWords, toParagraph, toWords } from "../utils";
import moment from "moment";
import { toWordsconver } from "../redux/action/api";
import Signature from "../assets/img/mdsign.png";
import DM_SANS_NORMAL from "../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../assets/DM_Sans/static/DMSans-Italic.ttf";
Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL, fontWeight: 700 },
    {
      src: DM_SANS_BOLD,
      fontStyle: "bold",
      // fontWeight: "bold",
    },
    {
      src: DM_SANS_ITALIC,
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "20px",
    height: "400",
    pageBreakInside: "avoid",
    fontSize: 10.5,
    fontFamily: "DM_SANS",
  },
  header: {
    textAlign: "center",
    // fontSize: "25px",
    // fontWeight: "bolder",
    // marginTop: -40,
  },
  logo: {
    width: "50px",
    height: "50px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  watermarkText: { fontSize: "50px", transform: "rotate(60deg)" },
  itemContainer: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "",
    border: "1px solid #000",
  },
  itemContainer2: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "row",
  },
  borderBox: {
    border: "1px solid #000",
  },
  qrcodeContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  signatureContainer: {
    textAlign: "center",
    marginTop: "0px",
    alignSelf: "center",
  },
  signatureImage: {
    width: "120px",
    height: "30px",
  },
  qrcodeImage: {
    maxWidth: "120px",
    height: "100px",
  },
  textNormal: {
    fontSize: "15px",
    textAlign: "right",
  },
  hederContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 30,
  },
});

const ReceiptPdf = ({
  data = [],
  user = {},
  qrcode,
  total = 0,
  label = "ORIGINAL",
}) => {
  // const formattedTotal = total.toLocaleString("en-US", {
  //   style: "decimal",
  //   maximumFractionDigits: 2, // Set the number of decimal places you want
  // });
  return (
    <View wrap={false} style={styles.page}>
      {/* <Page size="A4"  fixed style={styles.page}> */}
      <View style={styles.watermark}>
        {/* <Image src={logo} style={styles.logo1} /> */}
        <Text style={styles.watermarkText}>{label}</Text>
      </View>

      <View wrap={false} style={{ flexDirection: "row" }}>
        <View style={{ width: "20%" }}>
          <Image src={logo} style={styles.logo} />
          {/* <Image src={kano} style={styles.logo} /> */}
        </View>
        <View style={{ width: "80%" }}>
          <Text style={styles.header}>
            {"Kano State Goverment".toUpperCase()}
          </Text>
          <Text style={{ textAlign: "center", marginTop: "5px", fontSize: 13 }}>
            KANO STATE INTERNAL REVENUE SERVICE(KIRS)
          </Text>
          <Text
            style={{
              textAlign: "center",
              marginTop: "5px",
              marginBottom: 20,
              fontSize: "9",
            }}
          >
            No.2 Bank Road, Kano
          </Text>
        </View>
        <View style={{ width: "20%" }}>
          <Image src={logo1} style={styles.logo} />
          {/* <Image src={kano} style={styles.logo} /> */}
        </View>
      </View>
      <View style={{ width: "100%", borderBottom: "2px solid #003311" }}></View>
      <View style={{ width: "100%", flexDirection: "row", marginTop: "-20px" }}>
        <Text
          style={{
            backgroundColor: "#003311",
            color: "#fff",
            paddingTop: 6,
            marginLeft: "35%",
            paddingBottom: 6,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 2,
            marginBottom: 10,
          }}
        >
          ONLINE TREASURY RECEIPT
        </Text>
      </View>
      <View style={styles.hederContainer}>
        <View style={{ width: "40%", marginRight: 10 }}>
          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11, fontWeight: "", marginBottom: 7 }}>
              Transaction ID:
            </Text>
            <Text style={{ fontSize: 11, fontWeight: "", marginBottom: 7 }}>
              {data[0]?.reference_number}
            </Text>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{user.tin}</Text> */}
          </View>

          <View style={styles.itemContainer2}>
            <View>
              <Text style={{ fontSize: 11, marginBottom: 2 }}>Payer Name:</Text>
              <Text style={{ fontSize: 11 }}>
                {user.org_name || user.name}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11, marginTop: 5 }}>
              KNID: {user.kn_id}
            </Text>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{data[0]?.reference_number}</Text> */}
          </View>

          <View style={styles.itemContainer2}>
            <Text style={{ fontSize: 11, marginBottom: 10 }}>
              {data[0]?.tax_parent_code}
            </Text>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{user.name}</Text> */}
          </View>
          <View style={styles.itemContainer2}>
            <View>
              <Text style={{ fontSize: 11, marginBottom: 2 }}>
                Revenue Head:
              </Text>
              <Text style={{ fontSize: 11, marginBottom: 5 }}>
                {data[0]?.description}
              </Text>
            </View>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{user.phone}</Text> */}
          </View>
          <View style={styles.itemContainer2}>
            <View>
              <Text style={{ fontSize: 11, marginBottom: 2 }}>
                Payment Validation:
              </Text>
              <Text style={{ fontSize: 11, marginBottom: 5 }}>
                {data[0]?.logId}
              </Text>
            </View>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{user.phone}</Text> */}
          </View>
          <View style={styles.itemContainer2}>
            <View>
              <Text style={{ fontSize: 11, marginBottom: 2 }}>Amount:</Text>
              <Text style={{ fontSize: 11, marginBottom: 5 }}>
                N{formatNumber(total)}
              </Text>
            </View>
            {/* <Text style= {{fontSize: 11, marginBottom: 10}}>{user.phone}</Text> */}
          </View>
        </View>
        <View style={{ width: "40%", marginRight: 5 }}>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11, marginBottom: 4 }}>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "normal",
                  //   fontStyle: "italic",
                }}
              >
                Amount in Words:
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "bold",
                  marginTop: 3,
                }}
              >
                {toWordsconver(total.toString().split(".")[0])?.toUpperCase()}
                NAIRA
                {total.toString().split(".")[1] !== "00"
                  ? ` and ${toWordsconver(total.toString().split(".")[1])} kobo`
                  : null}{" "}
                ONLY
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11, marginBottom: 4 }}>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "normal",
                }}
              >
                Payer Address:
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "bold",
                  marginTop: 1,
                }}
              >
                {user.address}
              </Text>
            </View>
          </View>

          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11, marginBottom: 4 }}>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "normal",
                }}
              >
                MDA:{" "}
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "bold",
                  marginTop: 1,
                }}
              >
                {data[0]?.mda_name}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11, marginBottom: 4 }}>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "normal",
                }}
              >
                {" "}
                Payment Period:{" "}
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: "bold",
                  marginTop: 1,
                }}
              >
                {data[0]?.date_from && data[0]?.date_to
                  ? `${moment(data[0]?.date_from).format(
                    "D  MMMM, YYYY"
                  )} - ${moment(data[0]?.date_to).format("D  MMMM, YYYY")}`
                  : moment(data[0]?.transaction_date).format("D  MMMM, YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.itemContainer2}>
            <View style={{ fontSize: 11, marginBottom: 4 }}>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "normal",
                }}
              >
                Printed On:
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  //   fontFamily: "CustomRoboto",
                  fontWeight: "bold",
                  marginTop: 1,
                }}
              >
                {moment().format("D - MMMM - YYYY hh:mm:ss A")}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ width: "40%" }}>
          <Text style={{ textAlign: "center", fontSize: 15 }}>
            {label} COPY
          </Text>
          <View style={styles.qrcodeContainer}>
            <Image src={qrcode} style={styles.qrcodeImage} />
          </View>
          <View style={styles.signatureContainer}>
            <Image src={Signature} style={styles.signatureImage} />
          </View>
          <Text style={{ fontSize: 11, marginBottom: 4, textAlign: "center" }}>
            Sani Abdulkadir Dambo
          </Text>
          <Text style={{ fontSize: 11, marginBottom: 10, textAlign: "center" }}>
            EXECUTIVE CHAIRMAN [KIRS]
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ReceiptPdf;
