import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBoxOpen,
  // faChartPie,
  faCog,
  // faFileAlt,
  faHandHoldingUsd,
  faSignOutAlt,
  // faTable,
  faTimes,
  faLock,
  faUserFriends,
  // faCalendarAlt,
  // faMapPin,
  // faEject,
  // faRocket,
  faCreditCard,
  faHome,
  faListAlt,
  faUser,
  faWallet,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Routes } from "../routes";
// import ThemesbergLogo from '../assets/img/themesberg.svg'
import ReactHero from "../assets/img/kigra.jpg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { logout } from "../redux/action/auth";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import { faAlgolia, faPaypal } from "@fortawesome/free-brands-svg-icons";

export default (props = {}) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const onCollapse = () => setShow(!show);
  const { extension, links } = props;
  const dispatch = useDispatch();
  const isPhoneSize = useIsPhoneSize();

  const { user, tax_account } = useSelector((s) => s.auth);
  // useEffect(()=>{
  //   if(!tax_account?.user_id){
  //     dispatch(logout(history))
  //   }
  // },[tax_account?.user_id])

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <>
        <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
          <Accordion.Item eventKey={eventKey}>
            <Accordion.Button
              as={Nav.Link}
              className="d-flex justify-content-between align-items-center"
            >
              <span>
                <span className="sidebar-icon">
                  <FontAwesomeIcon icon={icon} size="" />
                </span>
                <span className="sidebar-text">{title}</span>
              </span>
            </Accordion.Button>
            <Accordion.Body className="multi-level">
              <Nav className="flex-column">{children}</Nav>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
      imageHeight,
      imageWidth,
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={imageHeight || 20}
                height={imageWidth || 20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const sector = (item) => user.sector.includes(item);

  const gotoSector = () => {
    switch (sector) {
      case "NON TAX":
        history.push(Routes.NonTaxPayment.path);
        break;
      case "TAX":
        history.push(Routes.TaxPayment.path);
        break;
      case "VEHICLES":
        history.push(Routes.VehiclePayments.path);
        break;
      case "LGA":
        history.push(Routes.LgaPayments.path);
        break;
      case "LAND":
        history.push(Routes.LandPayments.path);
        break;
      default:
        history.push(Routes.Selection.path);
        break;
    }
  };

  return (
    <>
      {isPhoneSize ? (
        <center
          className="mobile-bottom-nav"
          style={{
            display: "flex",
            flexDirection: "row",
            height: 50,
          }}
        >
          <div
            onClick={() => {
              history.push(Routes.Selection.path);
            }}
            size="sm"
            className="m-1"
          >
            <FontAwesomeIcon
              icon={faHome}
              className="me-1"
              style={{ fontSize: "20px" }}
            />{" "}
            <br />
            Home
          </div>
          <div
            onClick={() => {
              history.push(Routes.UserProfile.path);
            }}
            size="sm"
            className="m-1"
          >
            <FontAwesomeIcon
              icon={faUser}
              className="me-1"
              style={{ fontSize: "20px" }}
            />{" "}
            <br />
            Profile
          </div>

          <div
            onClick={() => {
              history.push(Routes.UserTransactions.path);
            }}
            size="sm"
            className="m-1"
          >
            <FontAwesomeIcon icon={faListAlt} style={{ fontSize: "20px" }} />
            <br />
            Transaction
          </div>
          <div
            size="sm"
            className="m-1"
            onClick={() => {
              history.push(Routes.ExpressAcct2.path);
            }}
          >
            <FontAwesomeIcon
              icon={faBars}
              className="me-1"
              style={{ fontSize: "20px" }}
            />
            <br />+ Accounts
          </div>
          <div
            size="sm"
            className="m-1"
            onClick={() => {
              history.push(Routes.NewResetPassword.path);
            }}
          >
            <FontAwesomeIcon
              icon={faCog}
              className="me-1"
              style={{ fontSize: "20px" }}
            />
            <br />
            Reset Password
          </div>
          {/* <div
            className="mt-1 mb-1"
            size="sm"
            onClick={() => dispatch(logout(history))}
            color="danger"
          >
            <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: "20px" }} />{" "}
            <br />
            Logout
          </div> */}
        </center>
      ) : (
        <Navbar
          expand={false}
          collapseOnSelect
          variant="light"
          className="navbar-theme-primary px-4 d-md-none"
        >
          <Navbar.Brand
            className="me-lg-5"
            as={Link}
            to={
              extension ? Routes.Selection.path : Routes.DashboardOverview.path
            }
          >
            <Image src={ReactHero} className="navbar-brand-light" />
          </Navbar.Brand>
          <Navbar.Toggle
            as={Button}
            aria-controls="main-navbar"
            onClick={onCollapse}
          >
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </Navbar>
      )}
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3 bg-dark">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {user.name}</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title=""
                link={"/"}
                image={ReactHero}
                imageHeight={150}
                imageWidth={150}
              />

              <NavItem
                title="Generate Invoice"
                icon={faBoxOpen}
                link={Routes.Selection.path}
              />

              {/* <Dropdown.Divider className="my-3 border-indigo" /> */}
              {/* {links && links?.length ? (
                <>
                  {links.map((link, idx) => (
                    <Nav key={idx} className="flex-column pt-3 pt-md-0">
                      <NavItem
                        title={link.title}
                        link={link.path}
                        icon={link.icon}
                      />
                    </Nav>
                  ))}
                </>
              ) : null} */}
              <>
                {/* <NavItem
                  title="My Profile"
                  icon={faHandHoldingUsd}
                  link={Routes.UserProfile.path}
                /> */}
                <NavItem
                  title="My Transactions"
                  icon={faHandHoldingUsd}
                  link={Routes.UserTransactions.path}
                />
                <NavItem
                  title="Reset Password"
                  icon={faHandHoldingUsd}
                  link={Routes.NewResetPassword.path}
                />
                <NavItem
                  title="Add Accounts"
                  icon={faUserFriends}
                  link={Routes.ExpressAcct2.path}
                />
                <NavItem
                  title="Pay Invoice"
                  icon={faPaypal}
                  link={Routes.PayInvoice1.path}
                />
                <NavItem
                  title="Verify PayNow"
                  icon={faAlgolia}
                  link={Routes.VerifyPayNow.path}
                />
                {/* <CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>
                <NavItem title="Bootstrap Table" icon={faTable} link={Routes.BootstrapTables.path} />
              </CollapsableNavItem> */}

                {/* <CollapsableNavItem
                    eventKey="tables/"
                    title="Tables"
                    icon={faTable}
                  >
                    <NavItem
                      title="Bootstrap Table"
                      link={Routes.BootstrapTables.path}
                    />
                    <NavItem
                      title="Transactions"
                      icon={faHandHoldingUsd}
                      link={Routes.Transactions.path}
                    />
                    <NavItem
                      title="Tax Pay List"
                      icon={faHandHoldingUsd}
                      link={Routes.TaxPayers.path}
                    />
                  </CollapsableNavItem>

                  <Dropdown.Divider className="my-3 border-indigo" /> */}
              </>

              {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem
                  title="Forgot password"
                  link={Routes.ForgotPassword.path}
                />
                <NavItem
                  title="Reset password"
                  link={Routes.ResetPassword.path}
                />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
                
                
                </CollapsableNavItem> */}

              <Dropdown.Divider className="my-3 border-indigo" />
              <Button
                className="bg-danger"
                onClick={() => dispatch(logout(history))}
                color="red"
              >
                <FontAwesomeIcon icon={faLock} /> Logout
              </Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
