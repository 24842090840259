import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Label,
  Row,
  Table,
  Input,
  CardFooter,
  Alert,
  Spinner,
} from "reactstrap";
import { _fetchApi, _postApi } from "../../redux/action/api";
import moment from "moment";
import CustomModal from "../../components/UI/CustomModal";
import { CustomButton } from "../../components/UI";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatNumber, toCurrency } from "../../utils";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { Image } from "@themesberg/react-bootstrap";
import toast from "react-hot-toast";
import { View, Text } from "@react-pdf/renderer";
import RemarkAccordion from "./RemarkAccordion";

export default function ClearanceRecommendation() {
  const _form = {
    date_issued: "",
    tin: "",
    tax_file_no: "",
    taxID: "",
    recommendation: "",
    recommended_by: "",
    status: "recommended",
    query_type: "recommendation",
  };
  const [getLoading, setGetLoading] = useState(false);
  const [form, setForm] = useState(_form);
  const year = moment().format("YYYY");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((s) => s.auth);

  const [filter, setFilter] = useState("");
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState({});
  const [rejectLoading, setRejectLoading] = useState(false);
  const isPhoneSize = useIsPhoneSize();
  const getData = useCallback(() => {
    setGetLoading(true);
    _fetchApi(
      `/get/tax-clearance?query_type=select-status&status=initiated`,
      (resp) => {
        setGetLoading(false);
        if (resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            arr.push({ ...item, remarkLoading: false });
          });
          console.log(arr);
          setData(arr);
        }
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleReject = () => {
    setRejectLoading(true);
    _postApi(
      "/post/tax-clearance",
      {
        ...form,
        taxID: selectedItem.taxID,
        tax_file_no: selectedItem.tax_file_no,
        staff_name: user.name,
        recommended_by: user.name,
        tcc_ref: selectedItem.tcc_ref,
        remark: form.recommendation,
        status: "rejected",
        query_type: "rejection",
      },
      (res) => {
        setRejectLoading(false);
        if (res.success) {
          toast.success("Rejected");
          getData();
          setShowModal(false);
          setSelectedItem({});
        }
      },
      (err) => {
        setShowModal(false);
        setRejectLoading(false);
        console.log(err);
        toast.success("Error occured");
      }
    );
  };
  const [remarks, setRemarks] = useState([]);
  const [remarkLoading, setRemarkLoading] = useState(false);
  const getRemarks = (x, i) => {
    const arr = [];
    data.forEach((item, index) => {
      if (i === index) {
        arr.push({ ...item, remarkLoading: true });
      } else {
        arr.push(item);
      }
    });
    setData(arr);
    setSelectedItem(x);
    _fetchApi(
      `/get/tax-clearance?query_type=get-remarks&id=${x.tcc_ref}`,
      (resp) => {
        if (resp.success) {
          setRemarks(resp.data);
          setShowModal(true);
        }
        const arr = [];
        data.forEach((item, index) => {
          if (i === index) {
            arr.push({ ...item, remarkLoading: false });
          } else {
            arr.push(item);
          }
        });
        setData(arr);
      },
      (e) => {
        const arr = [];
        data.forEach((item, index) => {
          if (i === index) {
            arr.push({ ...item, remarkLoading: false });
          } else {
            arr.push(item);
          }
        });
        setData(arr);
        console.log(e);
      }
    );
  };

  const handleSubmit = () => {
    setForm(_form);
    setLoading(true);
    setLoading(true);
    _postApi(
      "/post/tax-clearance",
      {
        ...form,
        taxID: selectedItem.taxID,
        tax_file_no: selectedItem.tax_file_no,
        staff_name: user.name,
        recommended_by: user.name,
        tcc_ref: selectedItem.tcc_ref,
      },
      (res) => {
        setLoading(false);
        if (res.success) {
          toast.success("Submitted");
          getData();
          setShowModal(false);
          setSelectedItem({});
        }
      },
      (err) => {
        setShowModal(false);
        setLoading(false);
        console.log(err);
        toast.success("Error occured");
      }
    );
  };
  return (
    <Card className="mt-3">
      {/* {JSON.stringify(data)} */}
      <CardHeader className="bg-primary" style={{ color: "#fff" }}>
        <Row className=" m-0 p-0">
          {/* <Col md={2} className=" m-0 p-0">
            <Button size="sm" onClick={() => history.goBack()} color="primary">
              Back
            </Button>
          </Col> */}
          <Col className="text-center m-0 p-0 b=">
            <b>TCC RECOMMENTATION</b>
          </Col>
        </Row>
      </CardHeader>
      {getLoading ? (
        <center>
          <Spinner size="sm" />
        </center>
      ) : null}
      <CardBody>
        <div style={{ overflow: "scroll", height: "77vh" }}>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Action</th>
                <th>Tax Payer</th>
                <th>Type</th>
                <th>File No.</th>
                <th>TCC Ref.</th>
              </tr>
            </thead>
            <tbody>
              {data.length
                ? data.map((x, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      <CustomButton
                        loading={x.remarkLoading}
                        onClick={() => {
                          getRemarks(x, i);
                        }}
                        color="primary"
                        size="sm"
                      >
                        View
                      </CustomButton>
                    </td>
                    <td>{x.tax_payer}</td>
                    <td>{x.type}</td>
                    <td>{x.tax_file_no}</td>
                    <td scope="row">{x.tcc_ref}</td>
                  </tr>
                ))
                : null}
            </tbody>
          </Table>
          {!data?.length ? (
            <Alert className="text-center">No Record Found</Alert>
          ) : null}
        </div>

      </CardBody>
      <CustomModal
        size="lg"
        toggle={() => {
          setShowModal(!showModal);
        }}
        logo={
          <center className="p-0 text-center">
            <Image
              src={require("../../assets/img/kigra.jpg")}
              className="img-fluid"
              alt="Logo"
              style={{
                borderRadius: "64px",
                height: isPhoneSize ? "10%" : "20%",
                width: isPhoneSize ? "10%" : "20%",
                marginTop: "-70px",
                marginBottom: "0px",
              }}
            />
          </center>
        }
        isOpen={showModal}
      >
        <div className="p-0 m-0 mt--5">
          <div
            style={{ width: "100%", flexDirection: "row", marginTop: "-20px" }}
          >
            <span
              style={{
                backgroundColor: "#003311",
                color: "#fff",
                paddingTop: 6,
                marginLeft: "35%",
                paddingBottom: 6,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 2,
                marginBottom: 10,
              }}
            >
              RECOMMENDATION FORM
            </span>
          </div>
          <div
            style={{
              flexDirection: "row",
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Tax Payer Name</span>
                <span>{selectedItem.tax_payer}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TCC REF</span>
                <span>{selectedItem.tcc_ref}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Type</span>
                <span>{selectedItem.type}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TIN</span>
                <span>{selectedItem.tin || "N/A"}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Year </span>
                <span>{selectedItem.year}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Raised By </span>
                <span>{selectedItem.raised_by}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Issue Date</span>
                <span>{selectedItem.date_issued}</span>
              </div>
            </div>
          </div>
          <div>
            <Table size="sm" bordered responsive>
              <thead>
                <tr className="bg-success">
                  <th style={{ border: "none" }}></th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(2, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(1, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>{moment(selectedItem.year).format("YYYY")}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-right">
                    <i>Total Income</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_income)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Tax Paid</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_amount)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Date Tax Reciept Issued</i>
                  </td>
                  <td className="text-right">
                    <i> {moment(data.first_year).format("DD/MM/YYYY")}</i>
                  </td>
                  <td className="text-right">
                    <i>{moment(data.second_year).format("DD/MM/YYYY")}</i>
                  </td>
                  <td className="text-right">
                    <i> {moment(data.third_year).format("DD/MM/YYYY")}</i>
                  </td>
                </tr>{" "}
              </tbody>
            </Table>
            {remarks.length ? <RemarkAccordion remarks={remarks} /> : null}
            <Input
              type="textarea"
              name="recommendation"
              value={form.recommendation}
              placeholder="Enter remark"
              onChange={handleChange}
            />
          </div>

          <CardFooter className="text-center d-flex justify-content-around">
            <CustomButton
              loading={rejectLoading}
              onClick={handleReject}
              outlined
              color="danger"
            >
              Reject
            </CustomButton>
            <CustomButton loading={loading} onClick={handleSubmit}>
              Approve
            </CustomButton>
          </CardFooter>
        </div>
      </CustomModal>
    </Card>
  );
}
