import React, { useCallback, useEffect, useState } from "react";
import CustomDateRange from "../dashboard/CustomDateRange";
import moment from "moment";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { CounterWidget1 } from "../../components/Widgets";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { toParagraph } from "../../utils";
import { getColor } from "./TaxClearanceTab";
import { SearchBar } from "../../components/UI";
import { SearchBar1 } from "../../components/UI/SearchBar";
import { _fetchApi } from "../../redux/action/api";

function TCCDashboard() {
  const today = moment();
  const today1 = moment();
  const [getLoading, setGetLoading] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("initiated");
  const [search, setSearch] = useState("");
  const [dateRange, setDateRange] = useState({
    type: "daily",
    month: today.format("MM"),
    quarter: Math.ceil(today.format("MM") / 3).toString(),
    year: today.format("YYYY"),
    from: today1.subtract(1, "month").format("YYYY-MM-DD"),
    to: today.format("YYYY-MM-DD"),
  });
  const handleChangeDate = ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const monthStart = moment(`'${dateRange.year}-${value}-01'`).format(
          "YYYY-MM-DD"
        );
        const monthEnd = moment(`'${dateRange.year}-${value}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: monthStart,
          to: monthEnd,
          month: value,
        });
        break;
      case "year":
        const yearStart = moment(`'${value}-01-01'`).format("YYYY-MM-DD");
        const yearEnd = moment(`'${value}-12'`)
          .endOf("year")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: yearStart,
          to: yearEnd,
        });
        break;

      case "quarter":
        let startMonth, endMonth;
        let selectedQuarter = value;
        switch (selectedQuarter) {
          case "Q1":
            startMonth = 1;
            endMonth = 3;
            break;
          case "Q2":
            startMonth = 4;
            endMonth = 6;
            break;
          case "Q3":
            startMonth = 7;
            endMonth = 9;
            break;
          case "Q4":
            startMonth = 10;
            endMonth = 12;
            break;
          default:
            // Handle unexpected quarter values
            break;
        }
        const qStart = moment(`'${dateRange.year}-${startMonth}-01'`).format(
          "YYYY-MM-DD"
        );
        const qEnd = moment(`'${dateRange.year}-${endMonth}'`)
          .endOf("month")
          .format("YYYY-MM-DD");

        setDateRange({
          ...dateRange,
          from: qStart,
          to: qEnd,
          quarter: selectedQuarter,
        });
        break;
      case "from":
        setDateRange({
          ...dateRange,
          from: moment(value).format("YYYY-MM-DD"),
        });
        break;

      case "to":
        setDateRange({
          ...dateRange,
          to: moment(value).format("YYYY-MM-DD"),
        });
        break;
      default:
        break;
    }
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      [name]: value,
    }));
  };
  const getData = useCallback(() => {
    setGetLoading(true);
    _fetchApi(
      `/search-tax-clearance?status=${status}&search=${search}&in_from=${dateRange.from}&in_to=${dateRange.to}`,
      (resp) => {
        setGetLoading(false);
        if (resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            arr.push({ ...item, remarkLoading: false });
          });
          console.log(arr);
          setData(arr);
        }
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  }, [status, dateRange.from, dateRange.to]);
  const getData1 = () => {
    setGetLoading(true);
    _fetchApi(
      `/search-tax-clearance?status=${status}&val=${search}&in_from=${dateRange.from}&in_to=${dateRange.to}`,
      (resp) => {
        setGetLoading(false);
        if (resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            arr.push({ ...item, remarkLoading: false });
          });
          console.log(arr);
          setData(arr);
        }
      },
      (e) => {
        console.log(e);
        setGetLoading(false);
      }
    );
  };
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <div>
      <div className="my-1">
        <CustomDateRange
          type={dateRange.type}
          month={dateRange.month}
          quarter={dateRange.quarter}
          year={dateRange.year}
          from={dateRange.from}
          to={dateRange.to}
          handleChangeDate={handleChangeDate}
        />
      </div>
      <div className="row">
        <Col md={4}>
          <Card className="bg-white p-2">
            <CounterWidget1
              color="lightblue"
              category="Formal Sector"
              count={
                data?.filter((item) => item.type === "Formal Businesses")
                  ?.length||0
              }
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faPrint}
              iconColor="shape-secondary"
              showChanges={false}
              // onClick={() => history.push(Routes.ReceiptReportDetails.path+`?from=${dateRange.from}&to=${dateRange.to}`)}
            />
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-white p-2">
            <CounterWidget1
              color="lightblue"
              category="Informal Sector"
              count={
                data?.filter((item) => item.type === "Informal Businesses")
                  ?.length||0
              }
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faPrint}
              iconColor="shape-secondary"
              showChanges={false}
              // onClick={() => history.push(Routes.ReceiptReportDetails.path+`?from=${dateRange.from}&to=${dateRange.to}`)}
            />
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-white p-2">
            <CounterWidget1
              color="lightblue"
              category="KRIT"
             count={
                data?.filter((item) => item.type === "KRIT")
                  ?.length 
              }
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faPrint}
              iconColor="shape-secondary"
              showChanges={false}
              // onClick={() => history.push(Routes.ReceiptReportDetails.path+`?from=${dateRange.from}&to=${dateRange.to}`)}
            />
          </Card>
        </Col>
      </div>
      <Card>
        <div className="m-2 p-2">
          <Row>
            <Col md={6}>
              {" "}
              <SearchBar1
                filterText={search}
                onFilterTextChange={(e) => setSearch(e)}
                placeholder="Search By Tax Payer Name or Certificate No."
                getData={getData1}
                getLoading={getLoading}
              />
            </Col>
            <Col className="pt-2 text-right">
              <ButtonGroup>
                <Button
                  size="sm"
                  onClick={() => setStatus("initiated")}
                  color="info"
                >
                  Initiated
                </Button>
                <Button
                  size="sm"
                  onClick={() => setStatus("recommended")}
                  color="warning"
                >
                  Recommended
                </Button>
                <Button
                  size="sm"
                  onClick={() => setStatus("rejected")}
                  color="danger"
                >
                  Rejected
                </Button>
                <Button
                  size="sm"
                  onClick={() => setStatus("Approved")}
                  color="secondary"
                >
                  Approved
                </Button>
                <Button
                  size="sm"
                  onClick={() => setStatus("Printed")}
                  color="primary"
                >
                  Printed
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          {getLoading ? (
            <center>
              <Spinner size="sm" />
            </center>
          ) : null}
        </div>
        <div style={{ overflow: "scroll", height: "60vh" }}>
          <Table responsive className="mt-3">
            <thead>
              <tr>
                <th>Tax Payer</th>

                <th>File No.</th>

                <th>TCC Ref.</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {data?.length
                ? data?.map((x, i) => (
                    <tr key={i}>
                      <td>{x.tax_payer}</td>
                   
                      <td>{x.tax_file_no}</td>
                    
                      {/* <td>{x.income_source?.toUpperCase()}</td> */}
                      <td scope="row">{x.tcc_ref}</td>
                      <td scope="row">
                        <Badge color={getColor(x.status)}>
                          {toParagraph(x.status)}
                        </Badge>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
          {!data?.length ? (
            <Alert className="text-center">No Record Found</Alert>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

export default TCCDashboard;
