import React from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  Text,
  Font,
  Image,
} from "@react-pdf/renderer";
import { formatNumber } from "../../utils";
import DM_SANS_NORMAL from "../../assets/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../assets/DM_Sans/static/DMSans_24pt-Bold.ttf";
import DM_SANS_ITALIC from "../../assets/DM_Sans/static/DMSans-Italic.ttf";
import logo from "../../assets/img/kano.png";
import logo2 from "../../assets/img/kigra.jpg";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
  },
  logo: {
    width: "50px",
    height: "50px",
    marginTop: "8px",
  },

  logo2: {
    textAlign: "right",
    width: "50px",
    height: "50px",
    marginTop: "8px",
  },
  logo1: {
    width: "300px",
    height: "300px",
  },
  logoRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    marginTop: "-12px",
  },
  watermark: {
    opacity: 0.1,
    position: "absolute",
    top: 200,
    left: 170,
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  column: {
    width: "25%",
    padding: 10,
    textAlign: "center",
  },
  firstColumn: {
    width: "50%",
    padding: 10,
    textAlign: "center",
  },
  header: {
    fontSize: 18,
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    marginVertical: 10,
    padding: 5,
  },
});

// Font.register({
//   family: "DM_SANS",
//   fonts: [
//     { src: DM_SANS_NORMAL, fontWeight: 700 },
//     {
//       src: DM_SANS_BOLD,
//       fontStyle: "bold",
//     },
//     {
//       src: DM_SANS_ITALIC,
//       fontStyle: "italic",
//     },
//   ],
// });

const ReportPDF = ({
  sector = [],
  mda = [],
  revenue = [],
  top50 = [],
  banklyRevenues = [],
}) => {
  const sectorTotal = sector?.reduce((a, b) => a + parseFloat(b.total_amt), 0);
  const mdaTotal = mda?.reduce((a, b) => a + parseFloat(b.total_amt), 0);
  const revenueTotal = revenue?.reduce(
    (a, b) => a + parseFloat(b.total_amt),
    0
  );
  const top50Total = top50?.reduce((a, b) => a + parseFloat(b.dr), 0);
  const banklyTotal = banklyRevenues?.reduce(
    (a, b) => a + parseFloat(b.total_amt),
    0
  );

  return (
    <Document>
      <Page size="A4" style={{ padding: 5, fontSize: 11 }} >
        <View>
          {/* <View style={styles.watermark}>
            <Image src={logo} style={styles.logo1} />
          </View> */}
          <View>
            <View style={styles.logoRow}>
              <View>
                <Image src={logo} style={styles.logo} />
              </View>
              <View>
                <Image src={logo2} style={styles.logo2} />
              </View>
            </View>
            <Text
              style={[
                styles.header,
                { marginTop: -40, backgroundColor: "transparent" },
              ]}
            >
              {"Kano State Goverment".toUpperCase()}
            </Text>
            <Text
              style={{
                textAlign: "center",
                marginTop: -10,
                marginBottom: 10,
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              KANO INTEGRATED REVENUE MANAGEMENT AND ASSURANCE SYSTEM (KIRMAS)
            </Text>
          </View>
        </View>

        <View style={[styles.page, { justifyContent: "space-between" }]}>
          <Text style={styles.header}>Sector Reports</Text>
          <Text style={styles.header}>Total: {formatNumber(sectorTotal)}</Text>
        </View>

        {sector.map((item, id) => (
          <View style={styles.page}>
            <View style={{ width: "50%" }} key={item.total_amt}>
              <Text>{item.sector}</Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text style={{ textAlign: "right" }}>
                {formatNumber(item.total_amt)}
              </Text>
            </View>
          </View>
        ))}

        <View style={[styles.page, { justifyContent: "space-between" }]}>
          <Text style={styles.header}>MDA Reports</Text>
          <Text style={styles.header}>Total: {formatNumber(mdaTotal)}</Text>
        </View>

        {mda.map((item, id) => (
          <View style={styles.page}>
            <View style={{ width: "50%" }} key={item.mda_name}>
              <Text>{item.mda_name}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text>{item.sector}</Text>
            </View>
            <View style={{ width: "15%" }}>
              <Text style={{ textAlign: "right" }}>
                {formatNumber(item.total_amt)}
              </Text>
            </View>
          </View>
        ))}

        <View style={[styles.page, { justifyContent: "space-between" }]}>
          <Text style={styles.header}>Revenue Reports</Text>
          <Text style={styles.header}>Total: {formatNumber(revenueTotal)}</Text>
        </View>

        {revenue.map((item, id) => (
          <View style={styles.page}>
            <View style={{ width: "50%" }} key={item.description}>
              <Text>{item.description}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text>{item.rev_code}</Text>
            </View>
            <View style={{ width: "15%" }}>
              <Text style={{ textAlign: "right" }}>
                {formatNumber(item.total_amt)}
              </Text>
            </View>
          </View>
        ))}

        <View style={[styles.page, { justifyContent: "space-between" }]}>
          <Text style={styles.header}>Bank Reports</Text>
          <Text style={styles.header}>Total: {formatNumber(sectorTotal)}</Text>
        </View>

        {banklyRevenues.map((item, id) => (
          <View style={styles.page}>
            <View style={{ width: "60%" }} key={item.total}>
              <Text>{item.bank_name || "Others"}</Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={{ textAlign: "right" }}>
                {formatNumber(item.total)}
              </Text>
            </View>
          </View>
        ))}

        <View style={[styles.page, { justifyContent: "space-between" }]}>
          <Text style={styles.header}>Top 50 tax payers Reports</Text>
          <Text style={styles.header}>Total: {formatNumber(top50Total)}</Text>
        </View>

        {top50.map((item, id) => (
          <View style={styles.page}>
            <View style={{ width: "50%" }} key={item.tax_payer}>
              <Text>{item.tax_payer}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text>{item.description}</Text>
            </View>
            <View style={{ width: "15%" }}>
              <Text style={{ textAlign: "right" }}>
                {formatNumber(item.dr)}
              </Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};

export default ReportPDF;
