import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Label,
  Row,
  Table,
  Input,
  CardFooter,
  Container,
  Badge,
  Spinner,
} from "reactstrap";

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import { _fetchApi, _postApi } from "../../redux/action/api";
import moment from "moment";
import CustomModal from "../../components/UI/CustomModal";
import { CustomButton, SearchBar } from "../../components/UI";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { TaxClearancePDF } from "../TaxClearancePDF";
import { formatNumber, toCurrency } from "../../utils";
import useIsPhoneSize from "../../utils/useIsPhoneSize";
import { Alert, Image } from "@themesberg/react-bootstrap";
import toast from "react-hot-toast";
import RemarkAccordion from "./RemarkAccordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { SearchBar1 } from "../../components/UI/SearchBar";

export default function TCCPrinting() {
  const _form = {
    date_issued: "",
    tin: "",
    tax_file_no: "",
    taxID: "",
    status: "approved",
    query_type: "get-new-prints",
  };
  const [getLoading, setGetLoading] = useState(false);
  const [form, setForm] = useState(_form);
  const year = moment().format("YYYY");
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((s) => s.auth);
  const [show_pdf, setShowPDF] = useState(false);
  const [filter, setFilter] = useState("");
  const isPhoneSize = useIsPhoneSize();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState({});
  const [remarks, setRemarks] = useState([]);
  const getData1 = () => {
    setGetLoading(true);
    _fetchApi(
      `/search-tax-clearance-no-date?search=${filter}&status=approved,printed`,
      (resp) => {
        setGetLoading(false);
        if (resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            arr.push({ ...item, remarkLoading: false });
          });
          setData(arr);
        }
      },
      (e) => {
        setGetLoading(false);
        console.log(e);
      }
    );
  };
  const getData = useCallback(() => {
    setGetLoading(true);
    _fetchApi(
      `/get/tax-clearance?query_type=get-new-prints&status=approved`,
      (resp) => {
        setGetLoading(false);
        if (resp.success) {
          let arr = [];
          resp.data.forEach((item) => {
            arr.push({ ...item, remarkLoading: false });
          });
          setData(arr);
        }
      },
      (e) => {
        setGetLoading(false);
        console.log(e);
      }
    );
  }, []);
  const getRemarks = (x, i) => {
    setSelectedItem(x);
    const arr = [];
    data.forEach((item, index) => {
      if (i === index) {
        arr.push({ ...item, remarkLoading: true });
      } else {
        arr.push(item);
      }
    });
    setData(arr);
    _fetchApi(
      `/get/tax-clearance?query_type=get-remarks&id=${x.tcc_ref}`,
      (resp) => {
        const arr = [];
        data.forEach((item, index) => {
          if (i === index) {
            arr.push({ ...item, remarkLoading: false });
          } else {
            arr.push(item);
          }
        });
        setData(arr);
        if (resp.success) {
          console.log(resp.data);
          setShowModal(true);
          setRemarks(resp.data);
        }
      },
      (e) => {
        console.log(e);
        const arr = [];
        data.forEach((item, index) => {
          if (i === index) {
            arr.push({ ...item, remarkLoading: false });
          } else {
            arr.push(item);
          }
        });
        setData(arr);
      }
    );
  };
  const searchData = useCallback(() => {
    if (filter.length > 2)
      _fetchApi(
        `/get/tax-clearance?query_type=print-search&id=${filter}`,
        (resp) => {
          if (resp.success) {
            setData(resp.data);
          }
        },
        (e) => {
          console.log(e);
        }
      );
  }, [filter]);

  useEffect(() => {
    getData();
    // searchData();
  }, [getData]);
  const [check, setCheck] = useState(false);
  const handleSubmit = () => {
    // setForm(_form);
    setLoading(true);
    if (selectedItem.id)
      _postApi(
        "/post/tax-clearance",
        {
          ...form,
          taxID: selectedItem.taxID,
          tax_file_no: selectedItem.tax_file_no,
          staff_name: user.name,
          printed_by: user.name,
          id: selectedItem.tcc_ref,
          tcc_ref: selectedItem.tcc_ref,
          query_type: "update-printed",
        },
        (res) => {
          if (res.success) {
            setShowModal(false);
            setShowPDF(true);
            toast.success("Document loaded");
            getData();
            setTimeout(() => {
              setLoading(false);
            }, 4000);
          }
        },
        (err) => {
          toast.error("Error occured");
          setShowModal(false);
          setLoading(false);
          console.log(err);
        }
      );
    // console.log(form)
  };

  //   useEffect(() => {
  //     if (check) {
  //       handleSubmit();
  //     }
  //   }, [check]);
  return (
    <Card className="mt-3">
      <CardHeader className="bg-primary  d-flex justify-content-between">
        <Col md={2}>
          {show_pdf ? (
            <Button
              onClick={() => setShowPDF(false)}
              className="bg-danger text-white"
              size="sm"
            //   color="primary"
            >
              Close
            </Button>
          ) : (
            <Button
              onClick={() => history.goBack()}
              size="sm"
            //   color="primary"
            >
              Back
            </Button>
          )}
        </Col>
        <h4 style={{ color: "#fff" }} className="">
          TCC PRINTING
        </h4>
        <div></div>
      </CardHeader>
      {show_pdf ? (
        <CardBody>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="text-center">
                <PDFDownloadLink
                  document={<TaxClearancePDF data={selectedItem} />}
                  fileName={`${moment().format("YYYYMMDD hh:mm:ss")}-${selectedItem?.tax_payer
                    }.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <Button variant="primary">
                        <FontAwesomeIcon icon={faDownload} className="me-2" />
                        Download TCC PDF
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </div>
              <PDFViewer
                style={{
                  width: "100%",
                  height: "800px",
                  pageBreakInside: "avoid",
                }}
              >
                <TaxClearancePDF data={selectedItem} />
              </PDFViewer>
            </>
          )}
        </CardBody>
      ) : (
        <CardBody>
          {/* {JSON.stringify(form)} */}
          <Row>
            <Col md={8}>
              {" "}
              <SearchBar1
                filterText={filter}
                onFilterTextChange={(e) => setFilter(e)}
                placeholder="Search By Tax Payer Name or Certificate No."
                getData={getData1}
                getLoading={getLoading}
              />
            </Col>
            <Col>
              <Badge className="mt-2">{data.length}</Badge>
            </Col>
          </Row>
          {getLoading ? (
            <center>
              <Spinner size="sm" />
            </center>
          ) : null}
          <div style={{ overflow: "scroll", height: "69vh" }}>
            <Table responsive className="mt-3">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Tax Payer</th>
                  <th>Type</th>
                  <th>File No.</th>
                  {/* <th>Address</th> */}
                  {/* <th>Income Source</th> */}
                  <th>TCC Ref.</th>
                </tr>
              </thead>
              <tbody>
                {data.length
                  ? data.map((x, i) => (
                    <tr key={i}>
                      <td>
                        <CustomButton
                          loading={x.remarkLoading}
                          onClick={() => {
                            getRemarks(x, i);
                          }}
                          size="sm"
                          color="primary"
                        >
                          View
                        </CustomButton>
                      </td>
                      <td>{x.tax_payer}</td>
                      <td>{x.type}</td>
                      <td>{x.tax_file_no}</td>
                      {/* <td>{x.address}</td> */}
                      {/* <td>{x.income_source?.toUpperCase()}</td> */}
                      <td scope="row">{x.tcc_ref}</td>
                    </tr>
                  ))
                  : ""}
              </tbody>
            </Table>
            {!data?.length ? (
              <Alert className="text-center">No Record Found</Alert>
            ) : null}
          </div>
        </CardBody>
      )}
      <CustomModal
        size="lg"
        toggle={() => {
          setShowModal(!showModal);
        }}
        logo={
          <center className="p-0 text-center">
            <Image
              src={require("../../assets/img/kigra.jpg")}
              className="img-fluid"
              alt="Logo"
              style={{
                borderRadius: "64px",
                height: isPhoneSize ? "10%" : "20%",
                width: isPhoneSize ? "10%" : "20%",
                marginTop: "-70px",
                marginBottom: "0px",
              }}
            />
          </center>
        }
        isOpen={showModal}
      >
        <div className="p-0 m-0 mt--5">
          <div
            style={{
              width: "100%",
              flexDirection: "row",
              marginTop: "-20px",
            }}
          >
            <span
              style={{
                backgroundColor: "#003311",
                color: "#fff",
                paddingTop: 6,
                marginLeft: "35%",
                paddingBottom: 6,
                paddingLeft: 10,
                paddingRight: 10,
                marginTop: 2,
                marginBottom: 10,
              }}
            >
              TCC PRINTING PREVIEW
            </span>
          </div>
          <div
            style={{
              flexDirection: "row",
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Tax Payer Name</span>
                <span>{selectedItem.tax_payer}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TCC REF.</span>
                <span>{selectedItem.tcc_ref}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Type</span>
                <span>{selectedItem.type}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>TIN </span>
                <span>{selectedItem.tin || "N/A"}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Year </span>
                <span>{selectedItem.year}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Raised By </span>
                <span>{selectedItem.raised_by}</span>
              </div>
              <div
                style={{
                  margin: 0,
                  padding: 0,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  marginBottom: 5,
                  padding: 5,
                }}
              >
                <span>Issue Date</span>
                <span>{selectedItem.date_issued}</span>
              </div>
            </div>
          </div>
          <div>
            <Table size="sm" bordered responsive>
              <thead>
                <tr className="bg-success">
                  <th style={{ border: "none" }}></th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(2, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>
                      {moment(selectedItem.year)
                        .subtract(1, "year")
                        .format("YYYY")}
                    </b>
                  </th>
                  <th className="text-right">
                    <b>{moment(selectedItem.year).format("YYYY")}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-right">
                    <i>Total Income</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_income)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_income)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Tax Paid</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.first_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.second_amount)}</i>
                  </td>
                  <td className="text-right">
                    <i>{formatNumber(selectedItem.third_amount)}</i>
                  </td>
                </tr>
                <tr>
                  <td className="text-right">
                    <i>Date Tax Reciept Issued</i>
                  </td>
                  <td className="text-right">
                    <i> {moment(data.first_year).format("DD/MM/YYYY")}</i>
                  </td>
                  <td className="text-right">
                    <i>{moment(data.second_year).format("DD/MM/YYYY")}</i>
                  </td>
                  <td className="text-right">
                    <i> {moment(data.third_year).format("DD/MM/YYYY")}</i>
                  </td>
                </tr>{" "}
              </tbody>
            </Table>
          </div>
          {/* {JSON.stringify(selectedItem.printed )} */}
          {remarks.length ? <RemarkAccordion remarks={remarks} /> : null}
          <CardFooter className="text-center">
            <CustomButton
              className={selectedItem.printed > 0 ? "text-white bg-danger" : ""}
              loading={loading}
              onClick={() => {
                handleSubmit();
              }}
            >
              {selectedItem.printed > 0 ? "Re-Print" : "Print"}
            </CustomButton>
          </CardFooter>
        </div>
      </CustomModal>
    </Card>
  );
}
