import React, { useCallback, useEffect, useState } from "react";
import { _fetchApi, _postApi } from "../redux/action/api";
import { Input, Spinner, Table } from "reactstrap";
import { SearchBar } from "../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import { Button, Col, Row, ButtonGroup, Card } from "@themesberg/react-bootstrap";
import { faArrowCircleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import UserTransactions from "./UserTransactions";

function TaxPayer() {
  const _form = {
    limit: 50,
    offset: 0
  }
  const [form, setForm] = useState(_form);
  const [loading, setLoading] = useState(false);
  const [taxPayer, setTaxPayer] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [type, setType] = useState("individual");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const getData = useCallback(() => {
    setLoading(true);
    _postApi(
      "/auths/-post-users",
      {
        limit: form.limit,
        offset: form.offset,
        account_type: type,
        query_type: 'select-tax-payers'
      },
      (res) => {
        if (res.success && res.data.length) {
          setLoading(false);
          setData(res.data);
          setTotalItems(res.data[0].total_rows)
        }
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [form, type]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (filterText.length > 3) {
      _postApi(
        "/auths/-post-users",
        {
          limit: form.limit,
          offset: form.offset,
          query_type: 'select-tax-payer',
          account_type: type,
          user_id: filterText
        },
        (res) => {
          if (res.success && res.data.length) {
            setLoading(false);
            setData(res.data);
            setTotalItems(0)
          }
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      );
    }
  }, [type, filterText])

  const filterByType = data?.filter((item) => item?.account_type === type);

  // const filtered_data = filterByType?.filter(
  //   (item) =>
  //     item?.name.toLowerCase().includes(filterText.toLowerCase()) ||
  //     item?.taxID.toString().includes(filterText)
  // );

  const totalTaxPayer = filterByType.length;
  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / form.limit))

  }, [form.limit, totalItems])
  const gotoPage = (x) => {

    setForm((prevForm) => ({
      ...prevForm,
      offset: x * form.limit,
    }));
  };
  const currentPage = Math.ceil((form.offset + 1) / form.limit);
  return (
    <Card>
      {show ? (
        <Row>
          <Col>
            <Button onClick={() => setShow(false)}>
              <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
            </Button>
          </Col>
        </Row>
      ) : null}
      {show ? (
        <UserTransactions taxPayer={taxPayer} viewOnly />
      ) : (
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <h4>{type.toUpperCase()} TAX PAYERS</h4>
            </div>
            <div className="mb-2">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant={type === "individual" ? "dark" : "secondary"}
                  onClick={() => setType("individual")}
                >
                  Individual
                </Button>
                <Button
                  variant={type === "org" ? "dark" : "secondary"}
                  onClick={() => setType("org")}
                >
                  Organization
                </Button>
                {/* 
                NOTE: There is NO THING call "BANK TAX PAYER" 
                BANKs are also  ORGs. they can act as "AGENT(s)" 
                <Button
                  variant={type === "bank" ? "dark" : "secondary"}
                  onClick={() => setType("bank")}
                >
                  Bank
                </Button> */}
              </ButtonGroup>
            </div>
          </div>

          <div className="mb-2">
            <SearchBar
              filterText={filterText}
              onFilterTextChange={(val) => setFilterText(val)}
              placeholder="Search Tax Payer by Name or Tax ID"
            />
          </div>
          <div className="text-right">
            Total tax payers: <b>{totalItems}</b>{" "}
          </div>
          {loading && (
            <div>
              <Spinner />
              Loading...
            </div>
          )}
          {totalItems || currentPage > 2 ? (
            <Row>
              <Col md={3}>
                {/* ... (your existing code for the items per page dropdown) */}
              </Col>
              <Col>
                {currentPage > 2 && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(0)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(currentPage - 2)}>
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </Button>
                  </>
                )}
                {[...Array(Math.min(10, totalPages))].map((_, index) => {
                  const pageNumber = currentPage + index - Math.floor(10 / 2);
                  return pageNumber > 0 && pageNumber <= totalPages ? (
                    <Button
                      key={index}
                      size="sm"
                      className={currentPage === pageNumber ? "bg-light text-dark" : ""}
                      onClick={() => gotoPage(pageNumber - 1)}
                    >
                      {pageNumber}
                    </Button>
                  ) : null;
                })}
                {currentPage < totalPages && (
                  <>
                    <Button size="sm" onClick={() => gotoPage(currentPage)}>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                    <Button size="sm" onClick={() => gotoPage(totalPages - 1)}>
                      <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body className="pt-0">
              <table
                style={{ border: "" }}
                width={"100"}
                className="user-table align-items-center table-hover"
              >
                <thead>
                  <tr>
                    <th className="text-center">ACTION</th>
                    <th className="text-center">Tax ID</th>
                    <th className="text-center">TIN</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">State</th>
                    <th className="text-center">LGA</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((tax_payer, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setShow(true);
                            setTaxPayer(tax_payer);
                          }}
                          style={{ marginRight: 5 }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                      </td>
                      <td className="text-center">{tax_payer.taxID}</td>
                      <td className="text-center">{tax_payer.tin}</td>
                      <td>
                        {tax_payer.account_type === "org"
                          ? tax_payer.org_name
                          : tax_payer.name}
                      </td>
                      <td>{tax_payer.email}</td>
                      <td>{tax_payer.state}</td>
                      <td>{tax_payer.lga}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>

        </Card.Body>
      )}

    </Card>
  );
}

export default TaxPayer;
