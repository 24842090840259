import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons"; // Added faDownload
import { Col, Row, Button } from "@themesberg/react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer"; // Added PDFDownloadLink
import InvoicePdf from "../components/InvoicePdf";
import { useQuery } from "../utils";
import { _fetchApi, _postApi, apiURL } from "../redux/action/api";
import { useCallback } from "react";
import PaymentButton from "./payments/PaymentButton";
import { useHistory, useLocation } from "react-router-dom";
import LandTermalInvoice from "../components/LandTermalInvoice";
import { LandAccountantInvoice } from "./LandAccountantInvoice";
import { useSelector } from "react-redux";
import { CertificateOfCompletion } from "../components/CertificateOfCompletion";
import useIsPhoneSize from "../utils/useIsPhoneSize";
import TermalInvoice from "../components/TermalInvoice";
import moment from "moment";
import toast from "react-hot-toast";

export default ({ refNo = null }) => {
  const q = useQuery();
  const ref_no = refNo ? refNo : q.get("ref_no");
  const [qrcode, setQrCode] = useState(null);
  const userInfo = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [payModal, setPayModal] = useState(false);
  const [user, setUser] = useState({});
  const [user_id, setUserID] = useState(null);
  const [list, setList] = useState([]);
  const location = useLocation();
  const isAdmin = location.pathname.includes("/dashboard/");
  const [loading, setLoading] = useState(false);
  const agent = useSelector((state) => state.auth.user);
  const { tax_account = [] } = useSelector((state) => state.auth);
  const isMobile = useIsPhoneSize();

  useEffect(() => {
    if (ref_no) {
      setLoading(true);
      _fetchApi(
        `/transactions/retrieve?query_type=view_invoice&reference_number=${ref_no}&user_id=${userInfo.id}`,
        (resp) => {
          setList(resp.data);
          if (resp.data[0]?.user_id) {
            setLoading(false);
            setUserID(resp.data[0].user_id);
          }
        },
        (err) => {
          console.error(err);
          setLoading(false);
        },
        (err) => {
          console.log(err);
          setList([]);
          setLoading(false);
        }
      );
    }
    if (user_id) {
      _fetchApi(
        `/users/get-tax-payer?user_id=${user_id}`,
        (resp) => {
          if (resp.success) {
            setUser(resp.data);
          }
        },
        (err) => {
          console.error(err);
          setUser({});
        }
      );
    }
    if (ref_no) {
      const qrCodeUrl = `${apiURL}/transactions/get-qr-code?ref_no=${ref_no}`;
      fetch(qrCodeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("@@auth_token"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const qrCodeImageUrl = URL.createObjectURL(blob);
          setQrCode(qrCodeImageUrl);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [ref_no, user_id]);

  const final_list = list
    ? list
    : list.map((item) => ({ ...item, cr: item.dr }));


  // const [refNo, setRefNo] = useState(null);
  const handleSubmit = (transaction_type = "invoice") => {
    console.log("Handled payment");

    const tax_list = [];

    if (final_list.length) {
      final_list
        .filter((item) => item.checked === true)
        .forEach((item) => {
          tax_list.push({
            ...item,
            mda_var: item.mda_var,
            mda_val: item.mda_val,
            transaction_type: "payment",
            description: item.title,
            rev_code: item.economic_code,
            amount: item.tax_fee,
            service_category: item.tax_parent_code,
            item_code: item.id,
            sector: item.sector,
            phone: item.phone
              ? item.phone
              : tax_account.office_phone || tax_account.phone,
          });
        });
      tax_list.push({
        mda_var: final_list[0]?.mda_var,
        mda_val: final_list[0]?.mda_val,
        tax_code: final_list[0]?.tax_code,
        sector: final_list[0]?.sector,
        tax_parent_code: final_list[0]?.tax_parent_code,
        amount: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        description: final_list.map((itm) => itm.title).toString(),
        transaction_type: "invoice",
        rev_code: final_list[0]?.economic_code || "",
        service_category: final_list[0]?.tax_parent_code || "",
        item_code: final_list[0].id || "",
        mda_code: final_list[0]?.mda_code || "",
        mda_name: final_list[0]?.mda_name || "",
        department: final_list[0]?.department,
        phone: final_list[0].phone
          ? final_list[0].phone
          : tax_account.office_phone || tax_account.phone,
      });
      const final_final_list = {
        total: tax_list.reduce((it, id) => it + parseFloat(id.amount), 0),
        user_id: tax_account.taxID,
        nin: tax_account.nin,
        nin_id: tax_account.nin_id,
        agent_id: user.role === "user" ? "" : user.id,
        tax_list,
        tax_station: final_list[0].tax_station,
        transaction_type,
        transaction_date: moment().format("YYYY-MM-DD"),
        start_date: moment(final_list[0].from).format("YYYY-MM-DD"),
        end_date: moment(final_list[0].to).format("YYYY-MM-DD"),
        tax_payer: tax_account.org_name || tax_account.name,
        phone: tax_list[0].phone,
        invoice_status: final_list[0]?.invoice_status || "",
      };
      setLoading(true);
      _postApi(
        "/transactions/execute",
        final_final_list,
        (resp) => {
          if (resp.success) {
            setIsOpen(true);
            setLoading(false);
          } else {
            toast.error("Error");
            setLoading(false);
          }
        },
        (e) => {
          console.error("Error", e);
        }
      );
    }
  };
  const history = useHistory()

  return (
    <div className="p-0 m-0 h-100 text-center">
      <Col md={2}>
        <Button onClick={() => history.goBack()}>Back</Button>
      </Col>
      {/* {JSON.stringify(userInfo)} */}
      {/* <PDFViewer height="100%" width="70%">
        <InvoicePdf
          user={user}
          data={final_list
            .filter((item) => item.cr > 0)
            .map((l) => ({ ...l, tax_fee: l.cr }))}
          qrcode={qrcode}
        />
      </PDFViewer> */}
      {loading ? (
        <SkeletonTheme baseColor="#f1f1f1" highlightColor={"#ffffff"}>
          <p>
            <Skeleton count={15} height={50} />
          </p>
        </SkeletonTheme>
      ) : (
        <>
          <Row className="mb-4">
            <Col md={6} >
              <PaymentButton
                onMouseOver={() => { }}
                addStyle={{ with: "100%", marginTop: 10 }}
                amount={final_list.reduce((it, id) => it + parseFloat(id.dr), 0)}
                reference_no={ref_no}
                email={tax_account.org_email || tax_account.email}
                name={tax_account.org_name || tax_account.name}
                taxID={tax_account.taxID}
                phone={tax_account.office_phone || tax_account.phone}
                data={final_list}
                sector={final_list[0]?.sector}
                callback={handleSubmit}
                setPayModal={setPayModal}
                toggle={toggle}
              />
            </Col>
            <Col md={6}>
              {final_list[0]?.sector !== "LAND" ? null : (
                <>
                  <PDFDownloadLink
                    document={
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    }
                    fileName="invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faDownload} className="me-2" />
                          Download Invoice PDF
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                  {final_list[0]?.department === "SLTR" ? (
                    <PDFDownloadLink
                      document={
                        <CertificateOfCompletion
                          user={agent}
                          tax_account={tax_account}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Certificate of Completion PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : final_list[0]?.department === "Land" ? (
                    <PDFDownloadLink
                      document={
                        <LandAccountantInvoice
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                        />
                      }
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button variant="primary">
                            <FontAwesomeIcon
                              icon={faDownload}
                              className="me-2"
                            />
                            Download Payment Schedule PDF
                          </Button>
                        )
                      }
                    </PDFDownloadLink>
                  ) : null}
                </>
              )}
            </Col>
          </Row>
          {isMobile ? null : <>
            {final_list[0]?.sector !== "LAND" ? (
              <Row style={{ height: '70vh' }}>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <PDFDownloadLink
                        document={
                          <InvoicePdf
                            user={user}
                            data={final_list
                              .filter((item) => item.cr > 0)
                              .map((l) => ({ ...l, tax_fee: l.cr }))}
                            qrcode={qrcode}
                          />
                        }
                        fileName="invoice.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <Button variant="primary">
                              <FontAwesomeIcon icon={faDownload} className="me-2" />
                              Download PDF
                            </Button>
                          )
                        }
                      </PDFDownloadLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ height: '70vh' }}>
                      <PDFViewer height="100%" width="70%">
                        <InvoicePdf
                          user={user}
                          data={final_list
                            .filter((item) => item.cr > 0)
                            .map((l) => ({ ...l, tax_fee: l.cr }))}
                          qrcode={qrcode}
                        />
                      </PDFViewer>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <PDFDownloadLink
                    document={
                      <TermalInvoice
                        user={user}
                        agent={userInfo}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                      />
                    }
                    fileName="termal-invoice.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button variant="primary">
                          <FontAwesomeIcon
                            icon={faDownload}
                            className="me-2"
                          />
                          Termal Invoice
                        </Button>
                      )
                    }
                  </PDFDownloadLink>

                  <PDFViewer height="100%" width="70%">
                    <TermalInvoice
                      user={user}
                      agent={userInfo}
                      data={final_list
                        .filter((item) => item.cr > 0)
                        .map((l) => ({ ...l, tax_fee: l.cr }))}
                      qrcode={qrcode}
                    />
                  </PDFViewer>
                </Col>
              </Row>
            ) : (
              <>
                {final_list[0]?.department === "Land" ? (
                  <>
                    {" "}
                    <PDFViewer height="100%" width="50%">
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                        agent={agent}
                      />
                    </PDFViewer>
                    <PDFViewer height="100%" width="50%">
                      <LandAccountantInvoice
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                      />
                    </PDFViewer>
                  </>
                ) : final_list[0]?.department === "SLTR" ? (
                  <>
                    {" "}
                    <PDFViewer height="100%" width="50%">
                      <LandTermalInvoice
                        user={user}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                        qrcode={qrcode}
                        agent={agent}
                      />
                    </PDFViewer>
                    {/* {} */}
                    <PDFViewer height="100%" width="50%">
                      <CertificateOfCompletion
                        user={agent}
                        tax_account={tax_account}
                        data={final_list
                          .filter((item) => item.cr > 0)
                          .map((l) => ({ ...l, tax_fee: l.cr }))}
                      />
                    </PDFViewer>
                  </>
                ) : (
                  <PDFViewer height="100%" width="100%">
                    <LandTermalInvoice
                      user={user}
                      data={final_list
                        .filter((item) => item.cr > 0)
                        .map((l) => ({ ...l, tax_fee: l.cr }))}
                      qrcode={qrcode}
                      agent={agent}
                    />
                  </PDFViewer>
                )}
              </>
            )}
          </>}
        </>
      )}
    </div>
  );
};
