import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { FaClipboard, FaDownload } from "react-icons/fa";
import html2canvas from "html2canvas";
import { Col, Container, Button, Row } from "reactstrap";
import toast from "react-hot-toast";
import { useLocation, useSearchParams } from "react-router-dom";
export default function GetVerify({ match }) {
  const [currentQRIndex, setCurrentQRIndex] = useState(0);

  const location = useLocation();
  const { id } = match.params;
  const qrCodeRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const myParam = queryParams.get("type");
  const myParamArray = myParam ? myParam.split(",") : [];

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.opacity = 0;

    document.body.appendChild(textArea);

    textArea.select();

    try {
      document.execCommand("copy");
      console.log("Text copied to clipboard:", text);
      // Assuming you have toast implemented for copy success
      toast.success("copied to clipboard");
    } catch (err) {
      console.error("Unable to copy text to clipboard", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const shortenAndCopyToClipboard = (url) => {
    const maxLength = 40;
    const shortenedUrl =
      url.length > maxLength ? url.slice(0, maxLength) + "..." : url;

    return shortenedUrl;
  };

  const downloadAllQR = async () => {
    const promises = mappedValues.map((item, index) => {
      return new Promise((resolve) => {
        const image = new Image();
        image.crossOrigin = "Anonymous"; // Enable CORS for the image
        image.src = `https://cors-anywhere.herokuapp.com/${item.shortenedUrl}`; // Use a CORS proxy if needed

        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);

          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = `qr_code_${id}_${index}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve();
        };
      });
    });

    // Wait for all promises to resolve before proceeding
    await Promise.all(promises);
  };

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current)
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = `qr_code_${id}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error creating QR code image:", error);
        });
    }
  };

  const originalUrl = `https://verify.kirmas.kn.gov.ng/${id}`;
  const shortenedUrl = shortenAndCopyToClipboard(
    `https://verify.kirmas.kn.gov.ng/${id}`
  );
  const valuesToMap = myParamArray.slice(0, parseInt(myParam));
  const mappedValues = valuesToMap.map((value, index) => ({
    originalUrl: `https://verify.kirmas.kn.gov.ng/${id}/${
      index === 0
        ? "Original"
        : index === 1
        ? "Duplicate"
        : index === 2
        ? "Original"
        : index === 3
        ? "quadriplicate"
        : "Books"
    }`,
    shortenedUrl: shortenAndCopyToClipboard(
      `https://verify.kirmas.kn.gov.ng/${id}/${value}`
    ),
    type:
      index === 0
        ? "Original"
        : index === 1
        ? "Duplicate"
        : index === 2
        ? "Original"
        : index === 3
        ? "quadriplicate"
        : "Books",
    index,
  }));

  let num = parseInt(myParam);
  const arrayOfObjects = [];
  for (let i = 0; i < num; i++) {
    let newObj = {
      // Your object properties go here
      // Example properties:
      originalUrl: `https://verify.kirmas.kn.gov.ng/${id}/${
        i === 0
          ? "Original"
          : i === 1
          ? "Duplicate"
          : i === 2
          ? "Triplecate"
          : "Quadriplicate"
      }`,
      shortenedUrl: shortenAndCopyToClipboard(
        `https://verify.kirmas.kn.gov.ng/${id}/${
          i === 0
            ? "Original"
            : i === 1
            ? "Duplicate"
            : i === 2
            ? "Triplecate"
            : "Quadriplicate"
        }`
      ),
      type:
        i === 0
          ? "Original"
          : i === 1
          ? "Duplicate"
          : i === 2
          ? "Triplecate"
          : "Quadriplicate",
    };

    arrayOfObjects.push(newObj);
  }
  return (
    <div className="p-5">
      <div className="bg-white shadow rounded">
        <div className="">
          <h3 className="text-center  mt-3">QR CODE GENERATED</h3>
        </div>
        <div className="text-center mt-2">
          <Button onClick={downloadQRCode} title="Download QR Code">
            <FaDownload />
            Download QR Code
          </Button>
        </div>
        <div ref={qrCodeRef} className="text-center mt-4">
          <Row
            style={{ overflow: "scroll" }}
            className="d-flex justify-content-center mb-5"
          >
            {myParam === "" ? (
              <QRCode title="KIRMAS" size={256} value={originalUrl} />
            ) : (
              arrayOfObjects?.map((item, index) => (
                <Col md={3} className="m-2">
                  <div
                    key={index}
                    style={
                      {
                        // display: index === currentQRIndex ? "block" : "none",
                      }
                    }
                    className="  mb-3  "
                  >
                    <QRCode
                      title="KIRMAS"
                      size={256}
                      value={item.originalUrl}
                      id={`qr-code-${index}`}
                    />
                    {/* {index} */}
                  </div>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>

      {/* Download button */}
      {/* </Container> */}
      {/* </Col> */}
    </div>
  );
}
